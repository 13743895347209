// CollectionList.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CollectionList = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadCollections();
  }, []);

  const loadCollections = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      const response = await axios.get('/api/admin/career-collections', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCollections(response.data || []);
      setLoading(false);
    } catch (err) {
      console.error('Error loading collections:', err);
      setError(err.response?.data?.error || 'Failed to load collections');
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/admin');
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this collection?')) {
      try {
        const token = localStorage.getItem('adminToken');
        await axios.delete(`/api/admin/career-collections/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        loadCollections();
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to delete collection');
      }
    }
  };

  if (loading) return <div className="container mt-4">Loading...</div>;
  
  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Career Collections</h2>
        <Link to="/admin/collections/new" className="btn btn-primary">
          Create New Collection
        </Link>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <input
        type="text"
        className="form-control mb-4"
        placeholder="Search collections..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {collections.length === 0 ? (
        <div className="alert alert-info">
          No collections found. Create your first collection using the button above.
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Careers Count</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {collections
                .filter(collection =>
                  collection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  collection.description?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map(collection => (
                  <tr key={collection.id}>
                    <td>{collection.name}</td>
                    <td>{collection.description}</td>
                    <td>{collection.careers?.length || 0}</td>
                    <td>
                      <Link to={`/admin/collections/${collection.id}/edit`} className="btn btn-sm btn-primary me-2">
                        Edit
                      </Link>
                      <Link to={`/admin/collections/${collection.id}/manage`} className="btn btn-sm btn-info me-2">
                        Manage Careers
                      </Link>
                      <button
                        onClick={() => handleDelete(collection.id)}
                        className="btn btn-sm btn-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CollectionList;
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-image-crop/dist/ReactCrop.css';

const modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'video'],
      ['clean']
    ]
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link', 'video'
];

const CompanyEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  
  const [company, setCompany] = useState({
    name: '',
    description: '',
    website: '',
    logo_url: '',
    video_url: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 25,
    x: 0,
    y: 37.5,
    aspect: 4
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  // Add CSS for the crop container
  const cropContainerStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    overflow: 'hidden',
    border: '1px solid #dee2e6',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    backgroundColor: '#f8f9fa'
  };

  // Function to center and create initial crop
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  // Function to generate a cropped image file
  const generateCroppedImage = async () => {
    if (!completedCrop || !imgRef.current || !selectedFile) {
      console.log('Cannot generate cropped image:', { 
        hasCompletedCrop: !!completedCrop, 
        hasImgRef: !!imgRef.current,
        hasSelectedFile: !!selectedFile
      });
      return null;
    }

    const image = imgRef.current;
    
    // Calculate scaling factor between displayed size and natural size
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Scale crop values to match the natural image size
    const scaledCrop = {
      x: Math.round(completedCrop.x * scaleX),
      y: Math.round(completedCrop.y * scaleY),
      width: Math.round(completedCrop.width * scaleX),
      height: Math.round(completedCrop.height * scaleY)
    };

    console.log('Using scaled crop:', {
      original: completedCrop,
      scaled: scaledCrop,
      scale: { scaleX, scaleY },
      image: {
        displayed: { width: image.width, height: image.height },
        natural: { width: image.naturalWidth, height: image.naturalHeight }
      }
    });

    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 300;
    const ctx = canvas.getContext('2d');

    // Enable image smoothing
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Draw the cropped and scaled image
    ctx.drawImage(
      image,
      scaledCrop.x,
      scaledCrop.y,
      scaledCrop.width,
      scaledCrop.height,
      0,
      0,
      1200,
      300
    );

    // Debug: verify canvas has data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const hasData = imageData.data.some(x => x !== 0);
    console.log('Canvas data check:', { 
      hasData, 
      dataLength: imageData.data.length,
      firstPixels: Array.from(imageData.data.slice(0, 20))
    });

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            resolve(null);
            return;
          }
          console.log('Generated blob:', {
            size: blob.size,
            type: blob.type
          });
          blob.name = selectedFile.name;
          resolve(blob);
        },
        'image/jpeg',
        0.95
      );
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', {
        name: file.name,
        type: file.type,
        size: file.size
      });

      // Validate file size
      if (file.size > 5 * 1024 * 1024) {
        console.error('File too large:', file.size);
        setError('File size too large. Maximum size is 5MB.');
        return;
      }

      // Validate file type
      if (!file.type.startsWith('image/')) {
        console.error('Invalid file type:', file.type);
        setError('Only image files are allowed.');
        return;
      }

      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('File loaded, setting preview URL');
        setPreviewUrl(reader.result);
        setError(null); // Clear any previous errors
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to load image with CORS headers
  const loadImageWithCors = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = () => {
        // If CORS fails, try loading without crossOrigin
        console.log('CORS loading failed, falling back to local file');
        const img2 = new Image();
        img2.onload = () => resolve(img2);
        img2.onerror = () => reject(new Error('Failed to load image'));
        img2.src = url;
      };
      img.src = url;
    });
  };

  const onImageLoad = (e) => {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
    console.log('Image loaded:', {
      displayed: { width, height },
      natural: { width: naturalWidth, height: naturalHeight }
    });

    imgRef.current = e.currentTarget;

    // Center the crop
    const crop = centerAspectCrop(width, height, 4);
    console.log('Setting initial crop:', crop);
    setCrop(crop);
    setCompletedCrop({
      unit: 'px',
      x: crop.x * width / 100,
      y: crop.y * height / 100,
      width: crop.width * width / 100,
      height: crop.height * height / 100
    });
  };

  useEffect(() => {
    if (id) {
      fetchCompany();
    } else {
      setLoading(false);
    }
  }, [id]);

  const fetchCompany = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`/api/admin/companies/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCompany(response.data);
      if (response.data.logo_url) {
        try {
          // Try to load the image with CORS headers
          await loadImageWithCors(response.data.logo_url);
          setPreviewUrl(response.data.logo_url);
        } catch (err) {
          console.error('Failed to load image with CORS:', err);
          setError('Failed to load existing image. Please try uploading a new one.');
        }
      }
      setError(null);
    } catch (err) {
      setError('Failed to fetch company details');
      console.error('Error fetching company:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Starting form submission with:', {
        hasSelectedFile: !!selectedFile,
        hasCompletedCrop: !!completedCrop,
        hasImgRef: !!imgRef.current,
        cropState: crop
      });

      const token = localStorage.getItem('adminToken');
      const formData = new FormData();
      formData.append('name', company.name);
      formData.append('description', company.description);
      formData.append('website', company.website);
      formData.append('video_url', company.video_url);

      // If there's a cropped image, use that; otherwise use the original file
      if (selectedFile && completedCrop && imgRef.current) {
        console.log('Processing cropped image...');
        const croppedImage = await generateCroppedImage();
        if (croppedImage) {
          console.log('Appending cropped image to form:', {
            size: croppedImage.size,
            type: croppedImage.type,
            name: selectedFile.name
          });
          formData.append('logo', croppedImage, selectedFile.name);
        } else {
          console.error('Failed to generate cropped image');
          setError('Failed to process the cropped image. Please try again.');
          return;
        }
      } else if (selectedFile) {
        console.log('Using original file:', {
          name: selectedFile.name,
          type: selectedFile.type,
          size: selectedFile.size
        });
        formData.append('logo', selectedFile);
      }

      const headers = {
        Authorization: `Bearer ${token}`
      };

      console.log('Sending request to:', id ? 'PUT' : 'POST', 'endpoint');
      const response = id 
        ? await axios.put(`/api/admin/companies/${id}`, formData, { headers })
        : await axios.post('/api/admin/companies', formData, { headers });
      
      console.log('Server response:', response.data);
      navigate('/admin/companies');
    } catch (error) {
      console.error('Error saving company:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setError(error.response?.data?.error || 'Failed to save company');
    }
  };

  const handlePreview = () => {
    if (id) {
      window.open(`/companies/${id}`, '_blank');
    }
  };

  if (loading) return <div className="admin-editor">Loading...</div>;
  if (error) return <div className="admin-editor alert alert-danger">Error: {error}</div>;

  return (
    <div className="admin-editor">
      <h2>{id ? 'Edit Company' : 'Create Company'}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            value={company.name}
            onChange={(e) => setCompany({ ...company, name: e.target.value })}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Website</label>
          <input
            type="url"
            className="form-control"
            value={company.website}
            onChange={(e) => setCompany({ ...company, website: e.target.value })}
            placeholder="https://example.com"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Company Video</label>
          <input
            type="url"
            className="form-control"
            value={company.video_url}
            onChange={(e) => setCompany({ ...company, video_url: e.target.value })}
            placeholder="https://www.youtube.com/embed/VIDEO_ID"
          />
          <small className="text-muted">
            Enter an embeddable video URL (e.g., YouTube embed URL). For YouTube videos, use the format: https://www.youtube.com/embed/VIDEO_ID
          </small>
        </div>

        <div className="mb-3">
          <label className="form-label">Description</label>
          <ReactQuill
            value={company.description}
            onChange={(content) => setCompany({ ...company, description: content })}
            modules={modules}
            formats={formats}
            className="quill-editor"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Company Banner</label>
          <small className="d-block text-muted mb-2">
            Recommended size: 1200x300 pixels. The image will be used as a hero banner at the top of your company page.
            Supported formats: JPG, PNG. Maximum file size: 5MB.
          </small>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
            accept="image/*"
            ref={fileInputRef}
          />
          {previewUrl && (
            <div className="mt-2">
              <div style={cropContainerStyle}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => {
                    console.log('Crop changed:', percentCrop);
                    setCrop(percentCrop);
                  }}
                  onComplete={(c, pc) => {
                    console.log('Crop completed:', { pixels: c, percent: pc });
                    // Store pixel values instead of percentages
                    setCompletedCrop(c);
                  }}
                  aspect={4}
                  minHeight={100}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={previewUrl}
                    style={{ maxWidth: '100%' }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
              {completedCrop && (
                <div className="d-flex align-items-center mt-2">
                  <span className="text-success me-2">
                    <i className="fas fa-check-circle"></i>
                  </span>
                  <small className="text-muted">
                    Image cropped to banner size (1200x300). Click Update to save changes.
                  </small>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-4">
          <button type="submit" className="btn btn-primary me-2">
            {id ? 'Update Company' : 'Create Company'}
          </button>
          {id && (
            <button type="button" className="btn btn-secondary" onClick={handlePreview}>
              Preview Company Page
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CompanyEditor;

import axios from 'axios';

const API_BASE_URL = '/api';

// Configure axios defaults
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://127.0.0.1:3001';
}

// Add request interceptor for debugging
axios.interceptors.request.use(request => {
    if (process.env.NODE_ENV === 'development') {
        console.log('Starting Request:', {
            url: request.url,
            method: request.method,
            data: request.data
        });
    }
    return request;
});

// Add response interceptor for debugging
axios.interceptors.response.use(
    response => {
        if (process.env.NODE_ENV === 'development') {
            console.log('Response:', response);
        }
        return response;
    },
    error => {
        console.error('Response Error:', error);
        return Promise.reject(error);
    }
);

// Helper function to handle API responses
const handleResponse = async (promise) => {
    try {
        const response = await promise;
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        throw new Error(error.response?.data?.message || 'An error occurred');
    }
};

// Career and Job endpoints
export const fetchCareers = () => 
    handleResponse(axios.get(`${API_BASE_URL}/careers`));

export const fetchJob = (jobId) => 
    handleResponse(axios.get(`${API_BASE_URL}/job/${jobId}`));

export const fetchCompany = (id) =>
    handleResponse(axios.get(`${API_BASE_URL}/companies/${id}`));

export const fetchCompanyJobs = (id) =>
    handleResponse(axios.get(`${API_BASE_URL}/companies/${id}/jobs`));

// Company endpoints
export const fetchCompanies = (search) => 
    handleResponse(axios.get(`${API_BASE_URL}/companies${search ? `?search=${search}` : ''}`));

// Interview endpoints
export const startInterview = (jobId, level) =>
    handleResponse(axios.post(`${API_BASE_URL}/interviews/start`, { 
        jobId: parseInt(jobId), 
        level: parseInt(level) 
    }));

export const getQuestions = (jobId, level) =>
    handleResponse(axios.get(`${API_BASE_URL}/questions/${jobId}?level=${level}`));

export const getRemainingQuestions = (sessionId) =>
    handleResponse(axios.get(`${API_BASE_URL}/interviews/${sessionId}/questions`))
    .then(response => response.questions);

export const fetchQuestions = (jobId, level) =>
    handleResponse(axios.get(`${API_BASE_URL}/questions/${jobId}?level=${level}`));

export const saveAnswer = ({ sessionId, questionId, answer }) =>
    handleResponse(axios.post(`${API_BASE_URL}/interview-questions`, {
        sessionId,
        questionId,
        answer
    }));

export const endInterview = (sessionId) =>
    handleResponse(axios.post(`${API_BASE_URL}/end-interview`, { sessionId }));

export const transcribeAudio = (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob);
    return handleResponse(axios.post(`${API_BASE_URL}/transcribe`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }));
};

export const fetchInterviewReport = (referenceId) =>
    handleResponse(axios.get(`${API_BASE_URL}/interview-report/${referenceId}`));

export const fetchCareerCollections = () => 
    handleResponse(axios.get(`${API_BASE_URL}/admin/career-collections`));
export const fetchCareerCollection = (id) => 
    handleResponse(axios.get(`${API_BASE_URL}/admin/career-collections/${id}`));
  
export const createCareerCollection = (data) => 
handleResponse(axios.post(`${API_BASE_URL}/admin/career-collections`, data));

export const updateCareerCollection = (id, data) => 
handleResponse(axios.put(`${API_BASE_URL}/admin/career-collections/${id}`, data));

export const deleteCareerCollection = (id) => 
handleResponse(axios.delete(`${API_BASE_URL}/admin/career-collections/${id}`));

export const addCareersToCollection = (collectionId, careerIds) => 
handleResponse(axios.post(`${API_BASE_URL}/admin/career-collections/${collectionId}/careers`, { careerIds }));

export const removeCareerFromCollection = (collectionId, careerId) => 
handleResponse(axios.delete(`${API_BASE_URL}/admin/career-collections/${collectionId}/careers/${careerId}`));
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '70vh'
};

const defaultCenter = {
  lat: 39.8283,
  lng: -98.5795
};

const defaultZoom = 4;

// Define API key as a constant
const GOOGLE_MAPS_API_KEY = 'AIzaSyDPUwW-6znggbk7qzEmMXQ2GQnjZUBySQw';

const JobMapView = ({ jobs, isSearching, searchCount }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [groupedJobs, setGroupedJobs] = useState({});

  useEffect(() => {
    console.log('Jobs received in JobMapView:', jobs); // Debug log
    const grouped = jobs.reduce((acc, job) => {
      console.log('Processing job:', job); // Debug log
      if (job.city && job.city.lat && job.city.lng) {
        const key = `${job.city.lat}-${job.city.lng}`;
        if (!acc[key]) {
          acc[key] = {
            position: {
              lat: parseFloat(job.city.lat),
              lng: parseFloat(job.city.lng)
            },
            city: job.city.city,
            state: job.city.state_name,
            jobs: []
          };
        }
        acc[key].jobs.push(job);
      }
      return acc;
    }, {});
    console.log('Grouped jobs:', grouped); // Debug log
    setGroupedJobs(grouped);
  }, [jobs]);

  return (
    <div>
      {isSearching && (
        <div className="alert alert-info mb-3">
          Found {searchCount} matching positions
        </div>
      )}
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={defaultZoom}
          options={{
            gestureHandling: 'cooperative',
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true
          }}
        >
          {Object.values(groupedJobs).map((location, idx) => (
            <Marker
              key={`${location.position.lat}-${location.position.lng}`}
              position={location.position}
              onClick={() => setSelectedLocation(location)}
            />
          ))}

          {selectedLocation && (
            <InfoWindow
              position={selectedLocation.position}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div style={{ maxWidth: 200 }}>
                <h6>{selectedLocation.city}, {selectedLocation.state}</h6>
                <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                  {selectedLocation.jobs.map(job => (
                    <div key={job.id} className="mb-2">
                      <Link 
                        to={`/job-listing/${job.id}`}
                        className="text-decoration-none"
                      >
                        {job.job_title}
                      </Link>
                      <div className="text-muted small">{job.company_name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default JobMapView; 
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Reuse the styled components from JobBoard
const HeroSection = styled.section`
  height: 300px;
  background-image: linear-gradient(
       45deg,
       rgba(0, 32, 63, 0.8),
       rgba(0, 82, 155, 0.8)
     ),
     url('https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=2070');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 32, 63, 0.7) 0%,
      rgba(0, 82, 155, 0.7) 33%,
      rgba(0, 136, 204, 0.7) 66%,
      rgba(0, 32, 63, 0.7) 100%
    );
    background-size: 400% 400%;
    filter: blur(30px);
    opacity: 0.8;
    animation: gradientAnimation 20s ease infinite;
  }

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const CourseCard = styled(Card)`
  height: 100%;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #0052a3;
  margin-bottom: 1rem;
`;

const CareerCourses = () => {
  const navigate = useNavigate();

  const careerClusters = [
    { title: "Advanced Manufacturing", icon: "fas fa-industry", color: "#1E88E5" },
    { title: "Construction", icon: "fas fa-hard-hat", color: "#FFA000" },
    { title: "Supply Chain & Transportation", icon: "fas fa-truck-moving", color: "#43A047" },
    { title: "Arts, Entertainment, & Design", icon: "fas fa-palette", color: "#E53935" },
    { title: "Hospitality, Events, & Tourism", icon: "fas fa-hotel", color: "#8E24AA" },
    { title: "Financial Services", icon: "fas fa-chart-line", color: "#3949AB" },
    { title: "Education", icon: "fas fa-graduation-cap", color: "#00ACC1" },
    { title: "Healthcare & Human Services", icon: "fas fa-heartbeat", color: "#D81B60" },
    { title: "Public Service & Safety", icon: "fas fa-shield-alt", color: "#5E35B1" },
    { title: "Agriculture", icon: "fas fa-leaf", color: "#7CB342" },
    { title: "Energy & Natural Resources", icon: "fas fa-solar-panel", color: "#FB8C00" },
    { title: "Digital Technology", icon: "fas fa-laptop-code", color: "#00897B" },
    { title: "Marketing & Sales", icon: "fas fa-bullhorn", color: "#F4511E" },
    { title: "Management & Entrepreneurship", icon: "fas fa-briefcase", color: "#6D4C41" }
  ];

  const handleCourseClick = (cluster) => {
    navigate('/courses', { state: { cluster } });
  };

  return (
    <div>
      <HeroSection>
        <HeroContent>
          <HeroTitle>Career Experience Courses</HeroTitle>
          <p className="lead">
            Immerse yourself in real-world scenarios and build practical skills
          </p>
        </HeroContent>
      </HeroSection>

      <Container className="py-5">
        <Row xs={1} md={2} lg={3} className="g-4">
          {careerClusters.map((cluster, index) => (
            <Col key={index}>
              <CourseCard onClick={() => handleCourseClick(cluster.title)}>
                <Card.Body className="text-center p-4">
                  <IconWrapper>
                    <i className={cluster.icon} style={{ color: cluster.color }}></i>
                  </IconWrapper>
                  <Card.Title className="h4 mb-3">{cluster.title}</Card.Title>
                  <Card.Text className="text-muted">
                    Experience real-world scenarios in {cluster.title.toLowerCase()} through 
                    interactive GRASP challenges and guided learning.
                  </Card.Text>
                </Card.Body>
              </CourseCard>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CareerCourses;

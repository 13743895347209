import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

const DeviceCompatibilityCheck = () => {
  const [compatibilityMessage, setCompatibilityMessage] = useState(null);

  useEffect(() => {
    const checkCompatibility = async () => {
      try {
        // Check if RecordRTC is loaded
        if (typeof window.RecordRTC === 'undefined') {
          setCompatibilityMessage({
            type: 'warning',
            text: 'Audio recording library is not loaded. Please refresh the page or check your internet connection.'
          });
          return;
        }

        // Check if getUserMedia is supported
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          setCompatibilityMessage({
            type: 'warning',
            text: 'Your browser may not support audio recording. Please use a modern browser.'
          });
          return;
        }

        // Test audio permissions
        await navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            stream.getTracks().forEach(track => track.stop());
            setCompatibilityMessage({
              type: 'success',
              text: 'Audio recording is supported on your device.'
            });
          })
          .catch(() => {
            setCompatibilityMessage({
              type: 'warning',
              text: 'Please grant microphone permissions to use audio recording.'
            });
          });
      } catch (error) {
        setCompatibilityMessage({
          type: 'danger',
          text: 'Error checking device compatibility. Please ensure microphone permissions are enabled.'
        });
      }
    };

    checkCompatibility();
  }, []);

  return compatibilityMessage ? (
    <Alert variant={compatibilityMessage.type} className="mb-3">
      <i className={`fas fa-${compatibilityMessage.type === 'success' ? 'check-circle' : 'exclamation-triangle'} me-2`}></i>
      {compatibilityMessage.text}
    </Alert>
  ) : null;
};

export default DeviceCompatibilityCheck; 
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Accordion, Badge, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { useAudioRecording } from '../hooks/useAudioRecording';
import ResponseRecorder from './ResponseRecorder';

const PageTitle = styled.h1`
  color: #0052a3;
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 1rem 0;
  font-size: 2rem;
`;

const StyledCard = styled(Card)`
  margin-bottom: 2rem;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CategoryBadge = styled(Badge)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TipCard = styled(Card)`
  border-left: 4px solid #0052a3;
  margin-bottom: 1rem;
`;

const VideoContainer = styled.div`
  margin-bottom: 2rem;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
`;

const VideoTitle = styled.h2`
  color: #0052a3;
  margin-bottom: 1rem;
  font-size: 1.75rem;
`;

const InterviewPrep = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [feedbackMap, setFeedbackMap] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isRecording,
    startRecording,
    stopRecording,
    stopMediaStream
  } = useAudioRecording();

  useEffect(() => {
    return () => {
      stopMediaStream();
    };
  }, [stopMediaStream]);

  const commonQuestions = [
    {
      category: 'behavioral',
      question: 'Tell me about a time you faced a difficult challenge at work',
      tips: [
        'Use the STAR method (Situation, Task, Action, Result)',
        'Focus on positive outcomes',
        'Highlight your problem-solving skills'
      ],
      example: 'In my previous role, I was tasked with completing a project when two team members unexpectedly left...'
    },
    {
      category: 'behavioral',
      question: 'What is your greatest weakness?',
      tips: [
        'Be honest but strategic',
        'Show how you\'re working to improve',
        'Choose a weakness that isn\'t critical to the job',
        'Avoid cliché answers like "I\'m a perfectionist"'
      ],
      example: 'I sometimes struggle with public speaking. However, I\'ve been actively working on this by joining Toastmasters and volunteering to lead team presentations...'
    },
    {
      category: 'behavioral',
      question: 'Where do you see yourself in five years?',
      tips: [
        'Show ambition while being realistic',
        'Align your goals with the company\'s growth',
        'Demonstrate commitment to professional development'
      ],
      example: 'In five years, I aim to have developed deep expertise in this field and taken on increasing responsibilities...'
    },
    {
      category: 'behavioral',
      question: 'Why should we hire you?',
      tips: [
        'Connect your skills to job requirements',
        'Highlight unique qualities you bring',
        'Use specific examples from past experiences'
      ],
      example: 'My combination of technical skills and team collaboration makes me an ideal candidate...'
    },
    {
      category: 'technical',
      question: 'How do you stay updated with industry trends?',
      tips: [
        'Mention specific resources you use',
        'Discuss professional development activities',
        'Include networking aspects'
      ],
      example: 'I regularly attend industry conferences, follow key thought leaders...'
    },
    {
      category: 'situational',
      question: 'How would you handle a disagreement with a coworker?',
      tips: [
        'Emphasize communication skills',
        'Show conflict resolution abilities',
        'Demonstrate professionalism'
      ],
      example: 'I would first arrange a private meeting to discuss the issue...'
    },
    {
      category: 'leadership',
      question: 'Tell me about a time you had to lead a team',
      tips: [
        'Focus on team motivation',
        'Highlight decision-making',
        'Show results achieved'
      ],
      example: 'When leading a school project, I organized the team by...'
    },
    {
      category: 'problem-solving',
      question: 'Describe a time you had to solve a complex problem',
      tips: [
        'Show analytical thinking',
        'Explain your process',
        'Highlight the outcome'
      ],
      example: 'During a group assignment, we encountered a significant obstacle...'
    },
    {
      category: 'situational',
      question: 'How do you handle working under pressure?',
      tips: [
        'Provide specific examples',
        'Describe your stress management techniques',
        'Focus on positive outcomes'
      ],
      example: 'I thrive under pressure by maintaining organized task lists and breaking large projects into manageable steps...'
    },
    {
      category: 'behavioral',
      question: 'Tell me about a mistake you made and how you handled it',
      tips: [
        'Choose a genuine mistake',
        'Focus on the learning experience',
        'Explain how you fixed it',
        'Share what you do differently now'
      ],
      example: 'In my previous internship, I made an error in a client presentation. I immediately took responsibility...'
    },
    {
      category: 'situational',
      question: 'What would you do if you disagreed with a supervisor\'s decision?',
      tips: [
        'Show respect for authority',
        'Emphasize professional communication',
        'Focus on constructive solutions'
      ],
      example: 'I would request a private meeting to discuss my concerns, bringing data and alternative solutions...'
    }
  ];

  const starMethod = {
    Situation: 'Set the scene and provide context',
    Task: 'Describe your responsibility in the situation',
    Action: 'Explain the steps you took',
    Result: 'Share the outcomes of your actions'
  };

  const bodyLanguageTips = [
    'Maintain good eye contact',
    'Practice good posture',
    'Use appropriate hand gestures',
    'Smile naturally',
    'Mirror interviewer\'s body language subtly'
  ];

  const categories = ['all', 'behavioral', 'technical', 'situational', 'leadership', 'problem-solving'];

  const handleSubmitAnswer = async (answer, questionId) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/practice-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: commonQuestions[questionId].question,
          answer: answer,
          category: commonQuestions[questionId].category
        }),
      });
      
      const data = await response.json();
      setFeedbackMap(prev => ({
        ...prev,
        [questionId]: data.feedback
      }));
    } catch (error) {
      console.error('Error getting feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="py-3">
      <PageTitle>Interview Preparation Kit</PageTitle>

      {/* Video Section */}
      <VideoContainer>
        <VideoTitle>Interview Essentials</VideoTitle>
        <div style={{position: 'relative', padding: '56.25% 0 0 0'}}>
          <iframe 
            id="8f2cd7a4-d73c-89e0-85b6-1d9f9de63f8a-vod-2613e8fa-aed9-4d4e-b8f5-3a093a6315d3" 
            src="https://iframe.dacast.com/vod/8f2cd7a4-d73c-89e0-85b6-1d9f9de63f8a/2613e8fa-aed9-4d4e-b8f5-3a093a6315d3" 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
            frameBorder="0"
            scrolling="no"
            allow="autoplay;encrypted-media"
            allowFullScreen
          />
        </div>
      </VideoContainer>

      <Row>
        {/* Left Column */}
        <Col lg={4}>
          {/* STAR Method Section */}
          <StyledCard className="mb-4">
            <Card.Header as="h2" className="bg-primary text-white">
              The STAR Method
            </Card.Header>
            <Card.Body>
              {Object.entries(starMethod).map(([key, value]) => (
                <TipCard key={key} className="mb-2">
                  <Card.Body>
                    <h5 className="text-primary">{key}</h5>
                    <p className="mb-0">{value}</p>
                  </Card.Body>
                </TipCard>
              ))}
            </Card.Body>
          </StyledCard>

          {/* Body Language Tips Section */}
          <StyledCard>
            <Card.Header as="h2" className="bg-primary text-white">
              Body Language Tips
            </Card.Header>
            <Card.Body>
              {bodyLanguageTips.map((tip, index) => (
                <TipCard key={index} className="mb-2">
                  <Card.Body>
                    <i className="fas fa-check-circle text-success me-2"></i>
                    {tip}
                  </Card.Body>
                </TipCard>
              ))}
            </Card.Body>
          </StyledCard>
        </Col>

        {/* Right Column - Practice Questions */}
        <Col lg={8}>
          <StyledCard>
            <Card.Header as="h2" className="bg-primary text-white">
              Practice Questions
            </Card.Header>
            <Card.Body>
              <div className="mb-3 d-flex flex-wrap gap-2">
                {categories.map(category => (
                  <Button
                    key={category}
                    variant={activeCategory === category ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveCategory(category)}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </Button>
                ))}
              </div>

              <Accordion>
                {commonQuestions
                  .filter(q => activeCategory === 'all' || q.category === activeCategory)
                  .map((q, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <div>
                          {q.question}
                          <CategoryBadge bg="info" className="ms-2">
                            {q.category}
                          </CategoryBadge>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <h6 className="text-primary">Tips:</h6>
                        <ul>
                          {q.tips.map((tip, idx) => (
                            <li key={idx}>{tip}</li>
                          ))}
                        </ul>
                        <h6 className="text-primary">Example Answer:</h6>
                        <p>{q.example}</p>
                        
                        <div className="mt-4">
                          <ResponseRecorder
                            isRecording={isRecording}
                            onStartRecording={startRecording}
                            onStopRecording={stopRecording}
                            onSubmitResponse={(answer) => handleSubmitAnswer(answer, index)}
                          />
                        </div>

                        {isSubmitting && (
                          <div className="text-center mt-3">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        )}

                        {feedbackMap[index] && (
                          <Card className="mt-3 border-primary">
                            <Card.Body>
                              <h6 className="text-primary">Quick Feedback:</h6>
                              <div dangerouslySetInnerHTML={{ __html: feedbackMap[index] }} />
                            </Card.Body>
                          </Card>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </Card.Body>
          </StyledCard>
        </Col>
      </Row>
    </Container>
  );
};

export default InterviewPrep; 
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Modal, Badge } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { fetchCompany } from '../services/api';

const CompanyHome = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);

  // Fallback for motion if not available
  const MotionDiv = motion?.div || 'div';
  const MotionImg = motion?.img || 'img';

  // Default fallback image and video
  const defaultHeroImage = 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80&w=2070';
  const defaultVideoUrl = 'https://www.youtube.com/embed/dQw4w9WgXcQ';

  useEffect(() => {
    const loadCompanyDetails = async () => {
      if (!id) {
        setError('No company ID provided');
        setLoading(false);
        return;
      }

      try {
        const companyData = await fetchCompany(id);
        setCompany(companyData);
        setError(null);
      } catch (err) {
        console.error('Error fetching company:', err);
        setError(err.response?.data?.error || 'Failed to fetch company details');
      } finally {
        setLoading(false);
      }
    };

    loadCompanyDetails();
  }, [id]);

  // Learning paths by grade level
  const gradeBands = [
    {
      id: 'k-2',
      title: 'K-2',
      project: 'Junior Innovators',
      description: 'Hands-on STEM activities and basic tech concepts',
      icon: '🌈'
    },
    {
      id: '3-5',
      title: '3-5',
      project: 'Digital Explorers',
      description: 'Interactive coding and digital literacy',
      icon: '🚀'
    },
    {
      id: '6-8',
      title: '6-8',
      project: 'Tech Innovators',
      description: 'Project-based tech solutions',
      icon: '💡'
    },
    {
      id: '9-10',
      title: '9-10',
      project: 'Tech Pioneers',
      description: 'Advanced concepts and real-world applications',
      icon: '🔬'
    },
    {
      id: '11-12',
      title: '11-12',
      project: 'Future Leaders',
      description: 'Career preparation and advanced projects',
      icon: '🌐'
    }
  ];

  // Skill building courses
  const skillCourses = [
    {
      title: 'Technical Skills',
      courses: ['Programming Fundamentals', 'Data Analysis', 'Cloud Computing'],
      icon: '💻'
    },
    {
      title: 'Professional Skills',
      courses: ['Project Management', 'Team Collaboration', 'Communication'],
      icon: '🤝'
    },
    {
      title: 'Industry Knowledge',
      courses: ['Industry Trends', 'Best Practices', 'Case Studies'],
      icon: '📚'
    }
  ];

  if (loading) return <div className="text-center py-5">Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="company-home">
      {/* Hero Section */}
      <div className="hero-section position-relative text-white text-center">
        <div 
          className="hero-background" 
          style={{
            backgroundImage: `url(${company?.logo_url || defaultHeroImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '70vh',
            position: 'relative'
          }}
        >
          <div className="hero-overlay position-absolute top-0 start-0 w-100 h-100" 
               style={{
                 background: 'linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8))',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center'
               }}
          >
            <Container>
              <MotionDiv 
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="px-4"
              >
                <h1 className="display-3 fw-bold mb-4">{company?.name || 'Innovate. Educate. Transform.'}</h1>
                <p className="lead mb-5">
                  {company?.tagline || 'Empowering the next generation through real-world learning experiences'}
                </p>
                {company?.video_url && (
                  <Button 
                    variant="light" 
                    size="lg" 
                    onClick={() => setShowVideoModal(true)}
                    className="me-3"
                  >
                    <i className="fas fa-play-circle me-2"></i>
                    Watch Overview
                  </Button>
                )}
              </MotionDiv>
            </Container>
          </div>
        </div>
      </div>

      {/* Company Description Section */}
      <section className="py-5">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg={10}>
              <MotionDiv
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center"
              >
                <h2 className="display-6 mb-4">About {company?.name}</h2>
              </MotionDiv>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="border-0 shadow-sm overflow-hidden">
                <Row className="g-0">
                  <Col lg={5} className="order-lg-2">
                    <MotionDiv
                      initial={{ opacity: 0, x: 20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                      className="h-100"
                    >
                      <div className="position-relative h-100" style={{ minHeight: '440px' }}>
                        <img
                          src={company?.image_url || 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=2070'}
                          alt={`About ${company?.name || 'Company'}`}
                          className="w-100 h-100 object-fit-cover"
                          style={{ position: 'absolute', top: 0, left: 0 }}
                        />
                        {company?.founded && (
                          <div 
                            className="position-absolute bottom-0 end-0 bg-white p-3 rounded-3 shadow-sm m-3"
                            style={{ maxWidth: '200px', zIndex: 1 }}
                          >
                            <div className="text-center">
                              <div className="text-muted small">Founded</div>
                              <div className="h4 mb-0">{company.founded}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </MotionDiv>
                  </Col>
                  <Col lg={7} className="order-lg-1">
                    <MotionDiv
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="p-4 p-lg-4">
                        <div className="company-description mb-4" style={{ maxHeight: '440px', overflowY: 'auto' }}>
                          {company?.description ? (
                            <div 
                              className="lead"
                              dangerouslySetInnerHTML={{ __html: company.description }}
                            />
                          ) : (
                            <p className="lead">
                              We are a forward-thinking technology company dedicated to bridging 
                              the gap between education and real-world innovation. Our mission is 
                              to inspire and prepare students for the technological challenges of tomorrow.
                            </p>
                          )}
                          {company?.mission && (
                            <p className="mt-4">{company.mission}</p>
                          )}
                        </div>
                        <div className="d-flex gap-3 mt-4">
                          {company?.website && (
                            <Button 
                              variant="outline-primary"
                              href={company.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-globe me-2"></i>
                              Visit Website
                            </Button>
                          )}
                          {company?.video_url && (
                            <Button variant="outline-secondary" onClick={() => setShowVideoModal(true)}>
                              <i className="fas fa-play-circle me-2"></i>
                              Watch Video
                            </Button>
                          )}
                        </div>
                      </div>
                    </MotionDiv>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* K-12 Learning Projects Section */}
      <section className="py-5 bg-light">
        <Container>
          <h2 className="display-6 text-center mb-5">K-12 Industry-Connected Learning</h2>
          <p className="text-center lead mb-5">Experience real-world projects aligned with {company?.name}'s industry expertise</p>
          <Row className="g-4">
            {gradeBands.map((band, index) => (
              <Col key={band.id} md={6} lg={4}>
                <MotionDiv
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card className="h-100 border-0 shadow-sm hover-lift">
                    <Card.Body className="p-4">
                      <div className="d-flex align-items-center mb-3">
                        <span className="display-5 me-3">{band.icon}</span>
                        <div>
                          <h3 className="h5 mb-1">Grades {band.title}</h3>
                          <h4 className="h6 text-primary mb-0">{band.project}</h4>
                        </div>
                      </div>
                      <p className="text-muted mb-3">{band.description}</p>
                      <Button variant="outline-primary" size="sm">View Projects</Button>
                    </Card.Body>
                  </Card>
                </MotionDiv>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Professional Skill Building Section */}
      <section className="py-5">
        <Container>
          <h2 className="display-6 text-center mb-5">Professional Skill Building</h2>
          <p className="text-center lead mb-5">Master the skills needed for success at {company?.name}</p>
          <Row className="g-4">
            {skillCourses.map((track, index) => (
              <Col key={index} md={4}>
                <MotionDiv
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card className="h-100 border-0 shadow-sm hover-lift">
                    <Card.Body className="p-4">
                      <div className="text-center mb-4">
                        <span className="display-4">{track.icon}</span>
                        <h3 className="h4 mt-3">{track.title}</h3>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        {track.courses.map((course, idx) => (
                          <Button key={idx} variant="outline-secondary" size="sm">
                            {course}
                          </Button>
                        ))}
                      </div>
                    </Card.Body>
                  </Card>
                </MotionDiv>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Career Opportunities Section */}
      <section className="py-5 bg-light">
        <Container>
          <h2 className="display-6 text-center mb-5">Career Opportunities</h2>
          <p className="text-center lead mb-5">Explore simulated roles and prepare for your future at {company?.name}</p>
          <Row>
            <Col lg={8} className="mx-auto">
              <MotionDiv
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h3 className="h5 mb-0">Available Opportunities</h3>
                      <Link to={`/companies/${id}`} className="btn btn-primary btn-sm">
                        View All Positions
                      </Link>
                    </div>
                    {company?.jobs?.slice(0, 3).map((job) => (
                      <div key={job.id} className="border-bottom py-3">
                        <h4 className="h6 mb-2">{job.job_title}</h4>
                        <div className="d-flex gap-2 mb-2">
                          {job.city && (
                            <Badge bg="light" text="dark">
                              <i className="fas fa-map-marker-alt me-1"></i>
                              {job.city.city}
                            </Badge>
                          )}
                        </div>
                        <Link to={`/job-listing/${job.id}`} className="btn btn-outline-primary btn-sm">
                          Learn More
                        </Link>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </MotionDiv>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Video Modal */}
      {company?.video_url && (
        <Modal 
          show={showVideoModal} 
          onHide={() => setShowVideoModal(false)} 
          size="lg" 
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{company?.name} Overview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ratio ratio-16x9">
              <iframe 
                src={company.video_url} 
                title={`${company?.name} Overview`} 
                allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CompanyHome;

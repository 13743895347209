import React, { useEffect } from 'react';
import { Container, Card, Alert } from 'react-bootstrap';

const CareerCoach = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `!function(window){
      const host="https://labs.heygen.com",
      url=host+"/guest/streaming-embed?share=eyJxdWFsaXR5IjoiaGlnaCIsImF2YXRhck5hbWUiOiJjMjBmNGJkZGRiZTA0MWVjYmE5OGQ5MzQ0%0D%0ANGY4YjI5YiIsInByZXZpZXdJbWciOiJodHRwczovL2ZpbGVzMi5oZXlnZW4uYWkvYXZhdGFyL3Yz%0D%0AL2MyMGY0YmRkZGJlMDQxZWNiYTk4ZDkzNDQ0ZjhiMjliL2Z1bGwvMi4yL3ByZXZpZXdfdGFyZ2V0%0D%0ALndlYnAiLCJuZWVkUmVtb3ZlQmFja2dyb3VuZCI6ZmFsc2UsImtub3dsZWRnZUJhc2VJZCI6ImVk%0D%0AY2ZiMWM5NjdkMDRlMDU5YmRjZGY1YmVjZDJhZTUyIiwidXNlcm5hbWUiOiIyY2QyMjBkYTEyYWM0%0D%0AZmQyOGEyMDEzZTU2Y2Y0NDg0NyJ9&inIFrame=1",
      clientWidth=document.body.clientWidth,
      wrapDiv=document.createElement("div");
      wrapDiv.id="heygen-streaming-embed";
      const container=document.createElement("div");
      container.id="heygen-streaming-container";
      const stylesheet=document.createElement("style");
      stylesheet.innerHTML=\`
        #heygen-streaming-embed {
          position: relative;
          width: 100%;
          max-width: 800px;
          height: 450px;
          margin: 2rem auto;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          opacity: 0;
          visibility: hidden;
        }
        #heygen-streaming-embed.show {
          opacity: 1;
          visibility: visible;
        }
        #heygen-streaming-container {
          width: 100%;
          height: 100%;
        }
        #heygen-streaming-container iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      \`;
      const iframe=document.createElement("iframe");
      iframe.allowFullscreen=!1,
      iframe.title="Streaming Embed",
      iframe.role="dialog",
      iframe.allow="microphone",
      iframe.src=url;
      let visible=!1,initial=!1;
      window.addEventListener("message",(e=>{
        e.origin===host&&e.data&&e.data.type&&"streaming-embed"===e.data.type&&
        ("init"===e.data.action?(initial=!0,wrapDiv.classList.toggle("show",initial)):
        "show"===e.data.action?(visible=!0,wrapDiv.classList.toggle("expand",visible)):
        "hide"===e.data.action&&(visible=!1,wrapDiv.classList.toggle("expand",visible)))
      })),
      container.appendChild(iframe),
      wrapDiv.appendChild(stylesheet),
      wrapDiv.appendChild(container),
      document.getElementById('ai-coach-container').appendChild(wrapDiv)
    }(globalThis);`;
    document.body.appendChild(script);

    return () => {
      const embedElement = document.getElementById('heygen-streaming-embed');
      if (embedElement) {
        embedElement.remove();
      }
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container className="py-5">
      <h1 className="text-center mb-4">AI Career Coach</h1>
      
      <Alert variant="info" className="mb-4">
        <h4>Welcome to Your AI Career Coach!</h4>
        <p>
          Chat with our AI-powered career coach to explore careers that match your:
        </p>
        <ul>
          <li>Interests and passions</li>
          <li>Skills and strengths</li>
          <li>Values and goals</li>
          <li>Educational aspirations</li>
        </ul>
      </Alert>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title className="text-warning">
            <i className="fas fa-exclamation-triangle me-2"></i>
            Beta Feature Notice
          </Card.Title>
          <Card.Text>
            This is an experimental feature using AI technology. Please note:
            <ul>
              <li>AI may occasionally provide inaccurate or outdated information</li>
              <li>Always verify career information with trusted sources</li>
              <li>Consult with teachers, counselors, or career advisors for important decisions</li>
              <li>This is a learning tool to explore possibilities, not definitive career advice</li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>

      {/* Add a container for the AI coach */}
      <div id="ai-coach-container" className="text-center">
        <p className="text-muted mb-4">
          Your AI Career Coach is loading...
        </p>
      </div>
    </Container>
  );
};

export default CareerCoach;

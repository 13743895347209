import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

const SkeletonPulse = styled.div`
  display: inline-block;
  height: ${props => props.height || '1em'};
  width: ${props => props.width || '100%'};
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  border-radius: 4px;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const JobBoardSkeleton = () => {
  // Create an array of 6 items to show as skeleton cards
  return (
    <Container>
      <Row>
        {[...Array(6)].map((_, index) => (
          <Col key={index} lg={4} md={6} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body>
                <SkeletonPulse height="1.5rem" width="70%" className="mb-3" />
                <SkeletonPulse height="1rem" width="40%" className="mb-2" />
                <SkeletonPulse height="1rem" width="90%" className="mb-2" />
                <SkeletonPulse height="1rem" width="80%" className="mb-2" />
                <SkeletonPulse height="1rem" width="60%" className="mb-3" />
                <div className="mt-3">
                  <SkeletonPulse height="2.5rem" width="100%" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default JobBoardSkeleton; 
import { useState, useCallback } from 'react';

export const useAudioRecording = () => {
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);

  const stopMediaStream = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
  }, [stream]);

  const startRecording = async () => {
    try {
      // Check if RecordRTC is available
      if (typeof window.RecordRTC === 'undefined') {
        throw new Error('RecordRTC is not loaded. Please check your internet connection.');
      }

      // First check if getUserMedia is supported
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Audio recording is not supported in this browser');
      }

      // Request permissions with simpler constraints first
      const constraints = {
        audio: true
      };

      console.log('Requesting media permissions...');
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('Permission granted, stream obtained');

      // Create RecordRTC instance with more compatible options
      const options = {
        type: 'audio',
        mimeType: 'audio/webm;codecs=opus',
        recorderType: window.RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        desiredSampRate: 16000,
        bufferSize: 16384
      };

      console.log('Creating RecordRTC instance with options:', options);
      const recordRTC = new window.RecordRTC(mediaStream, options);

      recordRTC.startRecording();
      setRecorder(recordRTC);
      setStream(mediaStream);
      setIsRecording(true);
      
      console.log('Recording started successfully');
    } catch (error) {
      console.error('Detailed recording error:', error);
      
      // Provide more specific error messages
      if (error.name === 'NotAllowedError') {
        throw new Error('Microphone permission was denied. Please allow microphone access and try again.');
      } else if (error.name === 'NotFoundError') {
        throw new Error('No microphone found. Please ensure your microphone is properly connected.');
      } else if (error.name === 'NotReadableError') {
        throw new Error('Your microphone is busy or not accessible. Please ensure no other app is using it.');
      } else {
        throw new Error(`Microphone error: ${error.message || 'Unknown error occurred'}`);
      }
    }
  };

  const stopRecording = () => {
    return new Promise((resolve) => {
      if (!recorder) {
        resolve(null);
        return;
      }

      recorder.stopRecording(() => {
        try {
          const blob = recorder.getBlob();
          stopMediaStream();
          setIsRecording(false);
          setRecorder(null);
          resolve(blob);
        } catch (error) {
          console.error('Error stopping recording:', error);
          resolve(null);
        }
      });
    });
  };

  return {
    isRecording,
    startRecording,
    stopRecording,
    stopMediaStream
  };
};

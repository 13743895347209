import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { fetchInterviewReport } from '../services/api';
import { formatInterviewLevel } from '../utils/helpers';

const InterviewFeedback = () => {
  const [feedbackData, setFeedbackData] = useState(null);
  const [error, setError] = useState(null);
  const { referenceId } = useParams();

  useEffect(() => {
    const loadFeedback = async () => {
      try {
        const data = await fetchInterviewReport(referenceId);
        setFeedbackData(data);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setError('Failed to load feedback. Please try again later.');
      }
    };

    if (referenceId) {
      loadFeedback();
    }
  }, [referenceId]);

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        <i className="fas fa-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  }

  if (!feedbackData) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container py-5">
      <Card>
        <Card.Body>
          <h2 className="card-title text-center mb-4">
            <i className="fas fa-clipboard-check me-2"></i>
            Interview Feedback
          </h2>
          
          <h3>
            <i className="fas fa-briefcase me-2"></i>
            Job Details
          </h3>
          <p><strong>Title:</strong> {feedbackData.job.title}</p>
          <p><strong>Company:</strong> {feedbackData.job.company}</p>
          <p><strong>Career:</strong> {feedbackData.job.career}</p>
          <p><strong>Interview Level:</strong> {formatInterviewLevel(feedbackData.level)}</p>

          <h3 className="mt-4">
            <i className="fas fa-clock me-2"></i>
            Interview Details
          </h3>
          <p><strong>Start Time:</strong> {new Date(feedbackData.startTime).toLocaleString()}</p>
          <p><strong>End Time:</strong> {new Date(feedbackData.endTime).toLocaleString()}</p>

          <h3 className="mt-4">
            <i className="fas fa-comments me-2"></i>
            Interview Questions and Answers
          </h3>
          {feedbackData.questions.map((qa, index) => (
            <div key={index} className="mb-3">
              <p><strong>Q{index + 1}:</strong> {qa.question}</p>
              <p><em>Your Answer:</em> {qa.answer}</p>
            </div>
          ))}

          <h3 className="mt-4">
            <i className="fas fa-star me-2"></i>
            Feedback
          </h3>
          <div 
            className="feedback-content"
            dangerouslySetInnerHTML={{ __html: feedbackData.feedback }}
          />

          <div className="mt-4">
            <a 
              href={feedbackData.job.listingUrl} 
              className="btn btn-primary me-3"
            >
              <i className="fas fa-external-link-alt me-2"></i>
              View Job Listing
            </a>
            <a 
              href={`/interview-report/${referenceId}`}
              className="btn btn-secondary"
            >
              <i className="fas fa-file-alt me-2"></i>
              View Full Report
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InterviewFeedback;

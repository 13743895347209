import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { debounce } from '../utils/helpers';
import { fetchCareers } from '../services/api';
import InterviewCards from './InterviewCards';
import JobMapView from './JobMapView';
import JobBoardSkeleton from './JobBoardSkeleton';
import styled from 'styled-components';

// Reuse the styled components from Home.jsx
const HeroSection = styled.section`
  height: 300px; // Slightly shorter than home page
  background-image: linear-gradient(
       45deg,
       rgba(0, 32, 63, 0.8),
       rgba(0, 82, 155, 0.8)
     ),
     url('https://images.unsplash.com/photo-1503945438517-f65904a52ce6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 32, 63, 0.7) 0%,
      rgba(0, 82, 155, 0.7) 33%,
      rgba(0, 136, 204, 0.7) 66%,
      rgba(0, 32, 63, 0.7) 100%
    );
    background-size: 400% 400%;
    filter: blur(30px);
    opacity: 0.8;
    animation: gradientAnimation 20s ease infinite;
  }

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 0 1rem;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto Slab', serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SearchBox = styled(InputGroup)`
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  .form-control {
    font-size: 1.1rem;
    padding: 1rem;
    border: none;
    
    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    padding: 0 1.5rem;
  }
`;

const JobBoard = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [careers, setCareers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  // Extract all jobs from careers data
  const allJobs = careers.reduce((acc, career) => {
    return acc.concat(career.jobs || []);
  }, []);

  useEffect(() => {
    const loadInitialCareers = async () => {
      try {
        setIsLoading(true);
        const data = await fetchCareers();
        console.log('Careers data:', data);
        
        // Add safety check for careers data
        if (!Array.isArray(data)) {
          console.error('Invalid careers data received:', data);
          setCareers([]);
          return;
        }

        const careersWithCities = data.map(career => ({
          ...career,
          jobs: Array.isArray(career.jobs) ? career.jobs.map(job => ({
            ...job,
            city: job.city || null
          })) : [] // Provide empty array if jobs is undefined
        }));
        
        setCareers(careersWithCities);
      } catch (error) {
        console.error('Error loading careers:', error);
        setCareers([]); // Set empty array on error
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialCareers();
  }, []);

  const handleSearch = debounce(async (searchTerm) => {
    setSearchTerm(searchTerm);
    
    if (searchTerm.length < 2) {
      setSearchResults([]);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    try {
      const searchTermLower = searchTerm.toLowerCase();
      const uniqueJobs = new Map();
      
      careers.forEach(career => {
        // Add safety check for jobs array
        const jobs = Array.isArray(career.jobs) ? career.jobs : [];
        
        jobs.forEach(job => {
          if (
            job.job_title?.toLowerCase().includes(searchTermLower) ||
            job.company_name?.toLowerCase().includes(searchTermLower) ||
            job.job_description?.toLowerCase().includes(searchTermLower) ||
            career.occupation?.toLowerCase().includes(searchTermLower) ||
            career.alt_titles?.toLowerCase().includes(searchTermLower) ||
            career.onet_soc_code?.includes(searchTerm) ||
            job.city?.city?.toLowerCase().includes(searchTermLower) ||
            job.city?.state_name?.toLowerCase().includes(searchTermLower)
          ) {
            uniqueJobs.set(job.id, {
              ...career,
              jobs: [{
                ...job,
                city: job.city || null
              }]
            });
          }
        });
      });
      
      const filteredCareers = Array.from(uniqueJobs.values());
      setSearchResults(filteredCareers);
    } catch (error) {
      console.error('Error searching careers:', error);
      setSearchResults([]);
    }
  }, 300);

  const handleCareerSelect = (jobId) => {
    navigate(`/job-listing/${jobId}`);
  };

  // Calculate jobs to display based on search state
  const displayedJobs = isSearching 
    ? searchResults.reduce((acc, career) => acc.concat(career.jobs || []), [])
    : allJobs;

  // Calculate search result count
  const searchResultCount = isSearching 
    ? searchResults.reduce((acc, career) => acc + (career.jobs?.length || 0), 0)
    : 0;

  return (
    <div id="jobBoard">
      <HeroSection>
        <HeroContent>
          <HeroTitle>Find Your Perfect Position</HeroTitle>
          <SearchBox size="lg" className="mb-3">
            <InputGroup.Text className="bg-white border-0">
              <i className="fas fa-search"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              className="border-0 shadow-none"
              placeholder="Search by job title, company, skills, or career field..."
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button variant="primary" type="button">Search</Button>
          </SearchBox>
        </HeroContent>
      </HeroSection>

      <Container className="py-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1>Simulated Job Board</h1>
          <div className="d-flex gap-2">
            {isSearching && (
              <div className="d-none d-md-block">
                <span className="badge bg-info fs-6">
                  {searchResultCount} results found
                </span>
              </div>
            )}
            <Button 
              variant={showMap ? "outline-primary" : "primary"}
              onClick={() => setShowMap(!showMap)}
            >
              <i className={`fas fa-${showMap ? 'list' : 'map-marker-alt'} me-2`}></i>
              {showMap ? 'List View' : 'Map View'}
            </Button>
          </div>
        </div>

        {showMap ? (
          <JobMapView 
            jobs={displayedJobs} 
            isSearching={isSearching}
            searchCount={searchResultCount}
          />
        ) : (
          <div>
            <h2 className="text-center mb-4">
              Available Positions
              {isSearching && (
                <span className="badge bg-info ms-2">
                  {searchResultCount} results
                </span>
              )}
            </h2>
            {isLoading ? (
              <JobBoardSkeleton />
            ) : (
              <InterviewCards careers={isSearching ? searchResults : careers} />
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default JobBoard;

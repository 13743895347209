import React, { useState } from 'react';
import { Container, Row, Col, Nav, Card, Button, ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

const CourseContainer = styled(Container)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const NavigationPanel = styled.div`
  height: calc(100vh - 150px);
  overflow-y: auto;
  border-right: 1px solid #dee2e6;
  padding-right: 1rem;
`;

const ContentPanel = styled.div`
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 1rem;
`;

const NavItem = styled(Nav.Item)`
  margin-bottom: 0.5rem;
`;

const NavLink = styled(Nav.Link)`
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  
  &.active {
    background-color: #0052a3;
    color: white;
  }
`;

const CoursePlayer = () => {
  const [currentModule, setCurrentModule] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(0);

  // Example course data structure
  const courseData = {
    title: "Software Developer Career Experience",
    progress: 35,
    modules: [
      {
        title: "Understanding the Role",
        lessons: [
          {
            title: "What Does a Software Developer Do?",
            type: "content",
            content: "Content here..."
          },
          {
            title: "GRASP Challenge: Code Review",
            type: "challenge",
            challenge: {
              goal: "Improve code quality through review",
              role: "Senior Developer",
              audience: "Junior Developer Team",
              situation: "Your team has submitted new features for review",
              products: "Code review feedback and documentation"
            }
          }
        ]
      },
      // Add more modules as needed
    ]
  };

  const renderContent = () => {
    const lesson = courseData.modules[currentModule]?.lessons[currentLesson];
    
    if (!lesson) return null;

    if (lesson.type === 'challenge') {
      return (
        <Card>
          <Card.Header className="bg-primary text-white">
            <h4 className="mb-0">GRASP Challenge: {lesson.title}</h4>
          </Card.Header>
          <Card.Body>
            <div className="mb-3">
              <strong>Goal:</strong> {lesson.challenge.goal}
            </div>
            <div className="mb-3">
              <strong>Role:</strong> {lesson.challenge.role}
            </div>
            <div className="mb-3">
              <strong>Audience:</strong> {lesson.challenge.audience}
            </div>
            <div className="mb-3">
              <strong>Situation:</strong> {lesson.challenge.situation}
            </div>
            <div className="mb-3">
              <strong>Expected Products:</strong> {lesson.challenge.products}
            </div>
            <Button variant="primary" className="mt-3">Start Challenge</Button>
          </Card.Body>
        </Card>
      );
    }

    return (
      <Card>
        <Card.Body>
          <h4>{lesson.title}</h4>
          <div>{lesson.content}</div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <CourseContainer fluid>
      <Row className="mb-4">
        <Col>
          <h2>{courseData.title}</h2>
          <ProgressBar now={courseData.progress} label={`${courseData.progress}%`} />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <NavigationPanel>
            <Nav className="flex-column">
              {courseData.modules.map((module, moduleIndex) => (
                <div key={moduleIndex}>
                  <h6 className="mt-3 mb-2">{module.title}</h6>
                  {module.lessons.map((lesson, lessonIndex) => (
                    <NavItem key={lessonIndex}>
                      <NavLink
                        active={currentModule === moduleIndex && currentLesson === lessonIndex}
                        onClick={() => {
                          setCurrentModule(moduleIndex);
                          setCurrentLesson(lessonIndex);
                        }}
                      >
                        <i className={`fas ${lesson.type === 'challenge' ? 'fa-trophy' : 'fa-book'} me-2`}></i>
                        {lesson.title}
                      </NavLink>
                    </NavItem>
                  ))}
                </div>
              ))}
            </Nav>
          </NavigationPanel>
        </Col>
        <Col md={9}>
          <ContentPanel>
            {renderContent()}
          </ContentPanel>
        </Col>
      </Row>
    </CourseContainer>
  );
};

export default CoursePlayer;

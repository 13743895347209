import React from 'react';
import { Card, Container, Alert } from 'react-bootstrap';

const ApplyFeedback = ({ feedback, jobTitle = '', companyName = '' }) => {
  if (!feedback) return null;

  return (
    <Container className="my-4">
      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h2 className="text-center mb-4">
            Application Feedback
            {(jobTitle && companyName) && (
              <small className="d-block text-muted">
                {jobTitle} at {companyName}
              </small>
            )}
          </h2>
          
          {/* Cover Letter Section */}
          <div className="mb-4">
            <h3 className="h4 text-primary">
              <i className="fas fa-file-alt me-2"></i>
              Cover Letter Analysis
            </h3>
            <div className="feedback-section p-3 bg-light rounded">
              <div dangerouslySetInnerHTML={{ __html: feedback.coverLetter }} />
            </div>
          </div>

          {/* Resume Section */}
          <div className="mb-4">
            <h3 className="h4 text-primary">
              <i className="fas fa-file me-2"></i>
              Resume Analysis
            </h3>
            <div className="feedback-section p-3 bg-light rounded">
              <div dangerouslySetInnerHTML={{ __html: feedback.resume }} />
            </div>
          </div>

          {/* Overall Match */}
          <div className="mt-4">
            <h3 className="h4 text-primary">
              <i className="fas fa-chart-pie me-2"></i>
              Overall Match
            </h3>
            <Alert variant="info">
              <div dangerouslySetInnerHTML={{ __html: feedback.overall }} />
            </Alert>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ApplyFeedback;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, InputGroup, Form, Button } from 'react-bootstrap';
import { debounce } from '../utils/helpers';
import styled from 'styled-components';

// Styled components
const HeroSection = styled.section`
  height: 300px;
  background-image: linear-gradient(
       45deg,
       rgba(0, 32, 63, 0.8),
       rgba(0, 82, 155, 0.8)
     ),
     url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 32, 63, 0.7) 0%,
      rgba(0, 82, 155, 0.7) 33%,
      rgba(0, 136, 204, 0.7) 66%,
      rgba(0, 32, 63, 0.7) 100%
    );
    background-size: 400% 400%;
    filter: blur(30px);
    opacity: 0.8;
    animation: gradientAnimation 20s ease infinite;
  }

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 0 1rem;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto Slab', serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SearchBox = styled(InputGroup)`
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  .form-control {
    font-size: 1.1rem;
    padding: 1rem;
    border: none;
    
    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    padding: 0 1.5rem;
  }
`;

const CompanyBoard = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const observer = useRef();
  
  const lastCompanyElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prev => prev + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const loadCompanies = async (pageNum, search = '') => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/companies?page=${pageNum}&search=${search}`);
      const data = await response.json();
      
      setCompanies(prev => pageNum === 1 ? data.companies : [...prev, ...data.companies]);
      setHasMore(data.currentPage < data.totalPages);
      setTotalCompanies(data.totalCompanies);
    } catch (err) {
      console.error('Error loading companies:', err);
      setError('Failed to load companies');
    } finally {
      setIsLoading(false);
    }
  };

  // Load initial companies
  useEffect(() => {
    loadCompanies(1, searchTerm);
  }, []);

  // Load more companies when page changes
  useEffect(() => {
    if (page > 1) {
      loadCompanies(page, searchTerm);
    }
  }, [page]);

  const handleSearch = debounce((value) => {
    setSearchTerm(value);
    setPage(1);
    if (value.length < 2) {
      loadCompanies(1, '');
      return;
    }
    loadCompanies(1, value);
  }, 300);

  return (
    <>
      <HeroSection>
        <HeroContent>
          <HeroTitle>Discover Great Companies</HeroTitle>
          <SearchBox>
            <Form.Control
              placeholder="Search companies by name, industry, or description..."
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button variant="primary">
              <i className="fas fa-search"></i>
            </Button>
          </SearchBox>
        </HeroContent>
      </HeroSection>

      <Container className="py-5">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        
        <div className="d-flex justify-content-between align-items-center mb-4">
          <p className="text-muted mb-0">
            <i className="fas fa-info-circle me-2"></i>
            <span className="fw-bold">Note:</span> "Simulated positions" are not real positions, and are only used for practice and learning purposes.
          </p>
          <p className="text-muted mb-0">
            <span className="fw-bold">Total Companies:</span> {totalCompanies}
            {searchTerm && <span className="ms-2">matching "{searchTerm}"</span>}
          </p>
        </div>

        {isLoading && companies.length === 0 ? (
          <Row>
            {[1, 2, 3, 4].map((placeholder) => (
              <Col key={placeholder} md={6} lg={3} className="mb-4">
                <Card className="placeholder-glow">
                  <Card.Img variant="top" className="placeholder" style={{ height: '160px' }} />
                  <Card.Body>
                    <Card.Title className="placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </Card.Title>
                    <Card.Text className="placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            {companies.map((company, index) => (
              <Col 
                key={company.id} 
                md={6} 
                lg={3} 
                className="mb-4"
                ref={companies.length === index + 1 ? lastCompanyElementRef : null}
              >
                <Card className="h-100 d-flex flex-column shadow-sm">
                  <Link to={`/companies/${company.id}`} className="text-decoration-none">
                    <img
                      loading="lazy"
                      className="card-img-top"
                      src={company.logo_url || 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop'}
                      alt={`${company.name} logo`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop';
                      }}
                      style={{ height: '160px', objectFit: 'cover' }}
                    />
                    <Card.Body className="flex-grow-1">
                      <Card.Title className="text-dark">{company.name}</Card.Title>
                      {company.industry && (
                        <div className="text-muted mb-2">
                          <i className="fas fa-industry me-2"></i>
                          {company.industry}
                        </div>
                      )}
                      {company.jobs && (
                        <div className="text-muted">
                          <i className="fas fa-briefcase me-2"></i>
                          {company.jobs.length} simulated {company.jobs.length === 1 ? 'position' : 'positions'}
                        </div>
                      )}
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        {isLoading && companies.length > 0 && (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {!isLoading && companies.length === 0 && (
          <div className="text-center mt-5">
            <h3>No companies found</h3>
            <p className="text-muted">Try adjusting your search terms</p>
          </div>
        )}
      </Container>
    </>
  );
};

export default CompanyBoard;
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';

const InterviewCards = ({ careers }) => {
  if (!Array.isArray(careers)) {
    console.error('Invalid careers prop:', careers);
    return null;
  }

  return (
    <Row id="interviewCards" className="g-4">
      {careers.map(career => {
        if (!Array.isArray(career.jobs) || career.jobs.length === 0) {
          return null;
        }

        return career.jobs.map(job => (
          <Col key={job.id} xs={12} md={6} lg={4} className="mb-4">
            <Card className="h-100 d-flex flex-column shadow-sm">
              <Card.Body className="flex-grow-1">
                <Card.Title>{job.job_title}</Card.Title>
                <Card.Text className="text-muted mb-3">
                  <i className="fas fa-building me-2"></i>
                  {job.company ? (
                    <Link 
                      to={`/companies/${job.company_id}`}
                      className="text-decoration-none text-muted company-link"
                    >
                      {job.company.name}
                    </Link>
                  ) : (
                    job.company_name
                  )}
                  {job.city && (
                    <div className="small">
                      <i className="fas fa-map-marker-alt me-2"></i>
                      {job.city.city}, {job.city.state_name}
                    </div>
                  )}
                </Card.Text>
              </Card.Body>
              <Card.Footer className="bg-transparent border-0 p-3">
                <Link 
                  to={`/job-listing/${job.id}`}
                  className="btn btn-outline-primary w-100 interview-btn"
                >
                  <i className="fas fa-briefcase me-2"></i>
                  Apply & Interview
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        ));
      })}
    </Row>
  );
};

export default InterviewCards;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { fetchJob } from '../services/api';
import LoadingOverlay from './LoadingOverlay';
import ApplyFeedback from './ApplyFeedback';

const JobApplication = () => {
  const [jobData, setJobData] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [resume, setResume] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [showFeedbackButton, setShowFeedbackButton] = useState(false);
  const videoRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const data = await fetchJob(id);
        setJobData(data);
      } catch (err) {
        setError(err.message || 'Failed to load job details');
      }
    };
    fetchJobDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!coverLetter || !resume) {
      setError('Please select both a cover letter and resume');
      return;
    }

    setLoading(true);
    setError(null);
    setFeedback(null);
    setShowVideo(true);
    setShowFeedbackButton(false);

    const formData = new FormData();
    formData.append('coverLetter', coverLetter);
    formData.append('resume', resume);
    formData.append('jobId', id);

    try {
      console.log('Submitting application for analysis...');
      const response = await fetch('/api/analyze-application', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Failed to submit application');
      }

      const data = await response.json();
      if (!data.feedback) {
        throw new Error('No feedback received');
      }
      
      console.log('Received feedback:', data.feedback);
      setFeedback(data.feedback);
      setShowFeedbackButton(true);

      if (videoRef.current && videoRef.current.ended) {
        setShowVideo(false);
      }
    } catch (err) {
      console.error('Error submitting application:', err);
      setError(err.message);
      setShowVideo(false);
    } finally {
      setLoading(false);
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (type === 'coverLetter') {
        setCoverLetter(file);
      } else {
        setResume(file);
      }
    }
  };

  if (!jobData) {
    return (
      <Container className="py-4">
        <div className="text-center">Loading job details...</div>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      {showVideo && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div style={{ textAlign: 'center' }}>
            <video
              ref={videoRef}
              autoPlay
              style={{ maxWidth: '80%', maxHeight: '80vh', marginBottom: '20px' }}
              onEnded={handleVideoEnded}
            >
              <source src="/video/resume.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {showFeedbackButton && (
              <button 
                className="btn btn-success btn-lg"
                onClick={handleVideoEnded}
                style={{ display: 'block', margin: '0 auto' }}
              >
                <i className="fas fa-check-circle me-2"></i>
                View Cover Letter & Resume Analysis
              </button>
            )}
          </div>
        </div>
      )}

      {loading && !showVideo && <LoadingOverlay />}
      
      <h2 className="text-center mb-4">
        Apply for {jobData?.job_title} at {jobData?.company_name}
      </h2>

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <h4 className="mb-3">Cover Letter Tips</h4>
              <ul className="tips-list">
                <li>Address your letter to the hiring manager</li>
                <li>Explain why you're interested in {jobData.company_name}</li>
                <li>Connect your skills to the {jobData.job_title} position</li>
                <li>Show enthusiasm and willingness to learn</li>
                <li>Keep it to one page</li>
              </ul>
              <button 
                className="btn btn-outline-primary btn-sm mt-2"
                onClick={() => window.open('/examples/coverletter.pdf', '_blank')}
              >
                <i className="fas fa-eye me-2"></i>
                View Example Cover Letter
              </button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <h4 className="mb-3">Resume Tips</h4>
              <ul className="tips-list">
                <li>Start with your contact information</li>
                <li>List your education and relevant coursework</li>
                <li>Include extracurricular activities and leadership roles</li>
                <li>Highlight any volunteer work or projects</li>
                <li>Use action words to describe your achievements</li>
              </ul>
              <button 
                className="btn btn-outline-primary btn-sm mt-2"
                onClick={() => window.open('/examples/resume.pdf', '_blank')}
              >
                <i className="fas fa-eye me-2"></i>
                View Example Resume
              </button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <div className="upload-box mb-4">
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileChange(e, 'coverLetter')}
                className="form-control"
                required
              />
              <p className="mt-2 mb-0">Upload Cover Letter</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="upload-box mb-4">
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileChange(e, 'resume')}
                className="form-control"
                required
              />
              <p className="mt-2 mb-0">Upload Resume</p>
            </div>
          </Col>
        </Row>

        <button 
          type="submit" 
          className="btn btn-primary btn-lg w-100"
          disabled={loading || !coverLetter || !resume}
        >
          <i className="fas fa-paper-plane me-2"></i>
          Submit Application for Review
        </button>
      </form>

      {feedback && jobData && !showVideo && (
        <ApplyFeedback 
          feedback={feedback}
          jobTitle={jobData.job_title}
          companyName={jobData.company_name}
        />
      )}
    </Container>
  );
};

export default JobApplication;

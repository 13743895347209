import React from 'react';
import { Card, Button } from 'react-bootstrap';

const InterviewLevelSelection = ({ onLevelSelect }) => {
  return (
    <div className="container padded-container">
      <Card>
        <Card.Body className="text-center">
          <h2 className="mb-4">Choose Your Interview Level</h2>
          <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
            <Button
              size="lg"
              variant="primary"
              onClick={() => onLevelSelect(1)}
            >
              Explorer Interview (Grades 6-10)
            </Button>
            <Button
              size="lg"
              variant="secondary"
              onClick={() => onLevelSelect(2)}
            >
              Advanced Interview (Grades 11+)
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InterviewLevelSelection;

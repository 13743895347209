import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Spinner } from 'react-bootstrap';
import { fetchInterviewReport } from '../services/api';
import { formatDate } from '../utils/helpers';

const InterviewReport = () => {
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const { referenceId } = useParams();

  useEffect(() => {
    const loadReport = async () => {
      try {
        const data = await fetchInterviewReport(referenceId);
        setReport(data);
      } catch (error) {
        console.error('Error fetching report:', error);
        setError('Failed to load interview report. Please try again later.');
      }
    };

    if (referenceId) {
      loadReport();
    }
  }, [referenceId]);

  const handlePrint = () => {
    window.print();
  };

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        <i className="fas fa-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  }

  if (!report) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container py-5">
      <h2 className="mb-4">
        <i className="fas fa-file-alt me-2"></i>
        Full Interview Report
      </h2>

      <Card className="mb-4">
        <Card.Body>
          <h3 className="card-title">
            <i className="fas fa-briefcase me-2"></i>
            Job Details
          </h3>
          <p><strong>Title:</strong> {report.job.title}</p>
          <p><strong>Company:</strong> {report.job.company}</p>
          <p><strong>Career:</strong> {report.job.career}</p>
          <p>
            <a href={report.job.listingUrl} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-external-link-alt me-2"></i>
              View Job Listing
            </a>
          </p>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h3 className="card-title">
            <i className="fas fa-clock me-2"></i>
            Interview Session
          </h3>
          <p><strong>Start Time:</strong> {formatDate(report.startTime)}</p>
          <p><strong>End Time:</strong> {formatDate(report.endTime)}</p>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h3 className="card-title">
            <i className="fas fa-comments me-2"></i>
            Questions and Answers
          </h3>
          {report.questions.map((qa, index) => (
            <div key={index} className="mb-3">
              <p><strong>Q{index + 1}:</strong> {qa.question}</p>
              <p><em>Your Answer:</em> {qa.answer}</p>
            </div>
          ))}
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h3 className="card-title">
            <i className="fas fa-star me-2"></i>
            Feedback
          </h3>
          <div dangerouslySetInnerHTML={{ __html: report.feedback }} />
        </Card.Body>
      </Card>

      <Button 
        variant="primary" 
        onClick={handlePrint}
        className="print-button"
      >
        <i className="fas fa-print me-2"></i>
        Print Report
      </Button>
    </div>
  );
};

export default InterviewReport;

import { useState } from 'react';
import axios from 'axios';
import { startInterview } from '../services/api';

export const useProgressiveInterview = (jobId) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [referenceId, setReferenceId] = useState(null);
  const [interviewLevel, setInterviewLevel] = useState(null);
  const [isInitializing, setIsInitializing] = useState(false);

  const initializeInterview = async (level) => {
    setIsInitializing(true);
    try {
      const response = await startInterview(jobId, level);
      console.log('Interview initialized:', response);

      setSessionId(response.sessionId);
      setReferenceId(response.referenceId);
      setInterviewLevel(level);
      setQuestions(response.questions);
      
      return response;
    } catch (error) {
      console.error('Error initializing interview:', error);
      throw error;
    } finally {
      setIsInitializing(false);
    }
  };

  const submitAnswer = async (answer) => {
    try {
      const currentQuestion = questions[currentQuestionIndex];
      await axios.post(`/api/interviews/${referenceId}/answer`, {
        questionId: currentQuestion.id,
        answer: answer,
        sessionId: sessionId
      });
      setCurrentQuestionIndex(prev => prev + 1);
    } catch (error) {
      console.error('Error submitting answer:', error);
      throw error;
    }
  };

  return {
    questions,
    currentQuestionIndex,
    sessionId,
    referenceId,
    interviewLevel,
    initializeInterview,
    submitAnswer,
    isInitializing,
  };
}; 
// CollectionManage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CollectionManage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collection, setCollection] = useState(null);
  const [careers, setCareers] = useState([]);
  const [selectedCareers, setSelectedCareers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      const [collectionResponse, careersResponse] = await Promise.all([
        axios.get(`/api/admin/career-collections/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/admin/careers', {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      setCollection(collectionResponse.data);
      setCareers(careersResponse.data);
      setLoading(false);
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err.response?.data?.error || 'Failed to load data');
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/admin');
      }
    }
  };

  const handleAddCareers = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      await axios.post(`/api/admin/career-collections/${id}/careers`, 
        { careerIds: selectedCareers },
        { headers: { Authorization: `Bearer ${token}` }}
      );
      setSelectedCareers([]);
      loadData();
    } catch (err) {
      console.error('Error adding careers:', err);
      setError(err.response?.data?.error || 'Failed to add careers');
    }
  };

  const handleRemoveCareer = async (careerId) => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      await axios.delete(`/api/admin/career-collections/${id}/careers/${careerId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      loadData();
    } catch (err) {
      console.error('Error removing career:', err);
      setError(err.response?.data?.error || 'Failed to remove career');
    }
  };

  const filteredCareers = careers.filter(career =>
    !collection?.careers.find(c => c.id === career.id) &&
    career.occupation.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div className="container mt-4">Loading...</div>;
  if (error) return <div className="container mt-4 alert alert-danger">{error}</div>;

  return (
    <div className="container mt-4">
      <h2>Manage Careers in {collection?.name}</h2>
      
      <div className="row">
        <div className="col-md-6">
          <h3>Available Careers</h3>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Search careers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {filteredCareers.map(career => (
              <div key={career.id} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedCareers.includes(career.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCareers([...selectedCareers, career.id]);
                    } else {
                      setSelectedCareers(selectedCareers.filter(id => id !== career.id));
                    }
                  }}
                />
                <label className="form-check-label">{career.occupation}</label>
              </div>
            ))}
          </div>
          
          <button
            className="btn btn-primary mt-3"
            onClick={handleAddCareers}
            disabled={selectedCareers.length === 0}
          >
            Add Selected Careers
          </button>
        </div>
        
        <div className="col-md-6">
          <h3>Current Careers in Collection</h3>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {collection?.careers.map(career => (
              <div key={career.id} className="d-flex justify-content-between align-items-center mb-2">
                <span>{career.occupation}</span>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleRemoveCareer(career.id)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionManage;
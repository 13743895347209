import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ToolCard = styled(Card)`
  height: 100%;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const ToolIcon = styled.div`
  font-size: 2.5rem;
  color: #0052a3;
  margin-bottom: 1rem;
`;

const PageTitle = styled.h1`
  color: #0052a3;
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 1rem 0;
  font-size: 2rem;
`;

const CareerTools = () => {
  const navigate = useNavigate();

  const tools = [
    {
      title: "Interview Preparation Kit",
      icon: "fas fa-comments",
      description: "Access our comprehensive interview preparation resources including common questions, STAR method guides, and industry-specific practice scenarios.",
      path: "/tools/interview-prep",
      features: ["Question Database", "STAR Method Guide", "Industry Questions", "Body Language Tips"]
    },
    {
      title: "Networking Guide",
      icon: "fas fa-network-wired",
      description: "Master professional networking with our complete guide to building and maintaining professional relationships.",
      path: "/tools/networking",
      features: ["LinkedIn Optimizer", "Email Templates", "Elevator Pitch Builder", "Network Tracker"]
    },
    /* Industry Research Dashboard commented out
    {
      title: "Industry Research Dashboard",
      icon: "fas fa-chart-line",
      description: "Stay informed with real-time industry insights, market trends, and company profiles to make informed career decisions.",
      path: "/tools/industry-research",
      features: ["Company Profiles", "Market Trends", "Growth Sectors", "Culture Insights"]
    },
    */
    /* Career Budget Planner commented out
    {
      title: "Career Budget Planner",
      icon: "fas fa-calculator",
      description: "Plan your financial future with our salary-based budgeting tool. Compare industry salaries and create custom budget plans.",
      path: "/tools/budget-planner",
      features: ["Salary Calculator", "Budget Templates", "Expense Tracker", "Financial Goals"]
    }
    */
  ];

  return (
    <Container className="py-3">
      <PageTitle>Career Tools</PageTitle>
      <Row className="g-4">
        {tools.map((tool, index) => (
          <Col md={6} key={index}>
            <ToolCard>
              <Card.Body className="p-4">
                <ToolIcon>
                  <i className={tool.icon}></i>
                </ToolIcon>
                <Card.Title className="h3 mb-3">{tool.title}</Card.Title>
                <Card.Text className="mb-4">
                  {tool.description}
                </Card.Text>
                <div className="mb-4">
                  <h6 className="text-muted">Features:</h6>
                  <ul className="list-unstyled">
                    {tool.features.map((feature, idx) => (
                      <li key={idx}>
                        <i className="fas fa-check text-success me-2"></i>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <Button 
                  variant="primary" 
                  onClick={() => navigate(tool.path)}
                  className="w-100"
                >
                  Access Tool
                </Button>
              </Card.Body>
            </ToolCard>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CareerTools; 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, Button, Container } from 'react-bootstrap';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await axios.get('/api/admin/companies', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        setCompanies(response.data);
        setFilteredCompanies(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError(err.response?.data?.details || err.message);
        setLoading(false);
        
        if (err.response?.status === 401) {
          navigate('/admin');
        }
      }
    };

    fetchCompanies();
  }, [navigate]);

  // Handle search across all fields
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (!searchTerm.trim()) {
      setFilteredCompanies(companies);
      return;
    }

    const filtered = companies.filter(company => {
      return (
        company.name?.toLowerCase().includes(searchTerm) ||
        company.description?.toLowerCase().includes(searchTerm) ||
        company.website?.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredCompanies(filtered);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this company?')) {
      try {
        const token = localStorage.getItem('adminToken');
        await axios.delete(`/api/admin/companies/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setCompanies(companies.filter(company => company.id !== id));
        setFilteredCompanies(filteredCompanies.filter(company => company.id !== id));
      } catch (err) {
        setError('Error deleting company');
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Companies</h2>
        <Link to="/admin/companies/new">
          <Button variant="primary">Add New Company</Button>
        </Link>
      </div>

      <div className="mb-4">
        <div className="input-group">
          <span className="input-group-text">
            <i className="fas fa-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Website</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.map(company => (
            <tr key={company.id}>
              <td>
                {company.logo_url && (
                  <img 
                    src={company.logo_url} 
                    alt={`${company.name} logo`} 
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                  />
                )}
              </td>
              <td>{company.name}</td>
              <td>
                <a href={company.website} target="_blank" rel="noopener noreferrer">
                  {company.website}
                </a>
              </td>
              <td>
                <div className="btn-group">
                  <Link 
                    to={`/admin/companies/edit/${company.id}`} 
                    className="btn btn-sm btn-outline-primary"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(company.id)}
                    className="btn btn-sm btn-outline-danger"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default CompanyList;

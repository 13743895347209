import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AdminBreadcrumb = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  
  // Only show breadcrumb for admin routes
  if (!pathSegments.includes('admin')) return null;

  return (
    <nav aria-label="breadcrumb" className="bg-light py-2">
      <div className="container">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/admin/dashboard">Admin Dashboard</Link>
          </li>
          {pathSegments.length > 2 && (
            <li className="breadcrumb-item active">
              {pathSegments[2].charAt(0).toUpperCase() + pathSegments[2].slice(1)}
            </li>
          )}
        </ol>
      </div>
    </nav>
  );
};

export default AdminBreadcrumb;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, ProgressBar, Spinner } from 'react-bootstrap';
import { useProgressiveInterview } from '../hooks/useProgressiveInterview';
import { useAudioRecording } from '../hooks/useAudioRecording';
import InterviewLevelSelection from './InterviewLevelSelection';
import QuestionDisplay from './QuestionDisplay';
import ResponseRecorder from './ResponseRecorder';
import { isMobile } from '../utils/helpers';
import DeviceCompatibilityCheck from './DeviceCompatibilityCheck';

const Interview = () => {
  const navigate = useNavigate();
  const { id: jobId } = useParams();
  const [showMobileWarning] = useState(() => isMobile());
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoType, setVideoType] = useState('intro'); // 'intro' or 'closing'
  const [error, setError] = useState(null);
  const [isFeedbackReady, setIsFeedbackReady] = useState(false);
  const [feedbackReferenceId, setFeedbackReferenceId] = useState(null);
  
  const {
    questions,
    currentQuestionIndex,
    sessionId,
    interviewLevel,
    initializeInterview,
    submitAnswer,
    isInitializing
  } = useProgressiveInterview(jobId);

  const {
    isRecording,
    startRecording,
    stopRecording,
    stopMediaStream
  } = useAudioRecording();

  const handleVideoEnd = () => {
    if (feedbackReferenceId) {
      navigate(`/feedback/${feedbackReferenceId}`);
    } else {
      console.error('No feedback reference ID found');
      setError('Failed to load feedback. Please try again later.');
    }
  };

  const handleLevelSelect = async (level) => {
    try {
      setError(null);
      setIsInterviewStarted(true);
      setShowVideo(true);
      setVideoType('intro');
      
      await initializeInterview(level);
    } catch (error) {
      console.error('Error starting interview:', error);
      setError('Failed to start the interview. Please try again later.');
      setIsInterviewStarted(false);
    }
  };

  const handleSubmitAnswer = async (answer) => {
    await submitAnswer(answer);
    
    // If this was the last question, show closing video and process feedback
    if (currentQuestionIndex === questions.length - 1) {
      setShowVideo(true);
      setVideoType('closing');
      
      // Process feedback right away
      try {
        const response = await fetch('/api/end-interview', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionId })
        });
        const data = await response.json();
        
        setFeedbackReferenceId(data.referenceId);
        setIsFeedbackReady(true);
      } catch (error) {
        console.error('Error ending interview:', error);
        setError('Failed to process interview feedback. Please try again later.');
      }
    }
  };

  const handleSkipVideo = () => {
    setShowVideo(false);
  };

  useEffect(() => {
    return () => {
      stopMediaStream();
    };
  }, [stopMediaStream]);

  if (!isInterviewStarted) {
    return (
      <>
        {error && (
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle me-2"></i>
            {error}
          </div>
        )}
        <InterviewLevelSelection onLevelSelect={handleLevelSelect} />
      </>
    );
  }

  if (showVideo) {
    return (
      <div className="container py-4">
        <Card className="shadow-sm">
          <Card.Body className="text-center">
            <video
              className="w-100 rounded mb-3"
              autoPlay
              onEnded={videoType === 'closing' ? handleVideoEnd : handleSkipVideo}
              controls={false}
            >
              <source 
                src={`/video/${videoType === 'intro' ? 'mock' : 'feedback'}.mp4`} 
                type="video/mp4" 
              />
              Your browser does not support the video tag.
            </video>
            {(videoType === 'intro' || (videoType === 'closing' && isFeedbackReady)) && (
              <Button 
                variant="primary" 
                size="lg" 
                onClick={videoType === 'intro' ? handleSkipVideo : handleVideoEnd}
                disabled={isInitializing || !questions.length}
                className="mt-3"
              >
                {isInitializing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Loading Interview...
                  </>
                ) : (
                  videoType === 'intro' ? 'Begin Interview' : 'Skip to Feedback'
                )}
              </Button>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div className="container py-4">
      <DeviceCompatibilityCheck />
      {showMobileWarning && (
        <div className="alert alert-warning" role="alert">
          <i className="fas fa-exclamation-triangle me-2"></i>
          <strong>Note:</strong> Audio recording may not work on some mobile devices. 
          For the best experience, please use a desktop browser.
        </div>
      )}

      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="text-center mb-3">
            <i className="fas fa-microphone-alt me-2"></i>
            Mock Interview Session
          </h2>
          
          <ProgressBar 
            now={((currentQuestionIndex + 1) / questions.length) * 100} 
            className="mb-3"
          />

          <QuestionDisplay 
            question={questions[currentQuestionIndex]} 
            questionNumber={currentQuestionIndex + 1}
            totalQuestions={questions.length}
          />

          <ResponseRecorder
            isRecording={isRecording}
            onStartRecording={startRecording}
            onStopRecording={stopRecording}
            onSubmitResponse={handleSubmitAnswer}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Interview;

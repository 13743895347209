// Debounce function for search inputs
export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Format date helper
export const formatDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

// Check if device is mobile
export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Format interview level
export const formatInterviewLevel = (level) => {
  return level === 1 ? 'Explorer (Grades 6-10)' : 'Advanced (Grades 11+)';
};

// Handle API errors
export const handleApiError = (error) => {
  console.error('API Error:', error);
  return error.response?.data?.message || error.message || 'An unexpected error occurred';
};

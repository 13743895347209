import React, { useEffect, useState } from 'react';
import CareerSearch from './CareerSearch';
import InterviewCards from './InterviewCards';
import { fetchCareers } from '../services/api';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';

// Styled components for the hero section
const HeroSection = styled.section`
  height: 300px;
  background-image: linear-gradient(
       45deg,
       rgba(0, 32, 63, 0.8),
       rgba(0, 82, 155, 0.8)
     ),
     url('https://images.unsplash.com/photo-1503945438517-f65904a52ce6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 32, 63, 0.7) 0%,
      rgba(0, 82, 155, 0.7) 33%,
      rgba(0, 136, 204, 0.7) 66%,
      rgba(0, 32, 63, 0.7) 100%
    );
    background-size: 400% 400%;
    filter: blur(30px);
    opacity: 0.8;
    animation: gradientAnimation 20s ease infinite;
  }

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 1200px) {
    font-size: 2.2rem;
  }

  @media (max-width: 992px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }

  @media (max-width: 375px) {
    font-size: 1.3rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;

  @media (max-width: 1200px) {
    font-size: 1.1rem;
  }

  @media (max-width: 992px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 576px) {
    font-size: 0.85rem;
  }

  @media (max-width: 375px) {
    font-size: 0.8rem;
  }
`;

// Add new styled components for the features section
const FeatureCard = styled(Card)`
  transition: transform 0.2s ease-in-out;
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  color: #0052a3;
  margin-bottom: 1rem;
`;

// Add a new styled component for the disclaimer
const DisclaimerText = styled.p`
  font-size: 0.85rem;
  color: #666;
  font-style: italic;
  margin-top: 1rem;
  padding: 0.5rem;
  border-left: 3px solid #ffc107;
  background-color: #fff8e5;
`;

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeroSection>
        <HeroContent className="container">
          <HeroTitle>Prepare for Your Dream Career</HeroTitle>
          <HeroSubtitle>Experience, Practice, and Master Your Career Path</HeroSubtitle>
        </HeroContent>
      </HeroSection>

      <Container className="py-4">
        <Row className="g-4">
          <Col md={4}>
            <FeatureCard>
              <Card.Body className="text-center p-5">
                <FeatureIcon>
                  <i className="fas fa-briefcase"></i>
                </FeatureIcon>
                <Card.Title className="h3 mb-4">Simulated Job Board & Interviews</Card.Title>
                <Card.Text className="mb-4">
                  Apply to simulated real-world positions. Get feedback on your cover letters and resumes. 
                  Practice simulated interview scenarios and get feedback on your performance.
                </Card.Text>
                <Button 
                  variant="primary" 
                  size="lg"
                  onClick={() => navigate('/job-board')}
                >
                  Start Practicing
                </Button>
              </Card.Body>
            </FeatureCard>
          </Col>

          <Col md={4}>
            <FeatureCard>
              <Card.Body className="text-center p-5">
                <FeatureIcon>
                  <i className="fas fa-building"></i>
                </FeatureIcon>
                <Card.Title className="h3 mb-4">Company Directory</Card.Title>
                <Card.Text className="mb-4">
                  Explore our curated list of top companies. Learn about their culture, open positions,
                  and get insights to help you prepare for your dream role.
                </Card.Text>
                <Button 
                  variant="primary" 
                  size="lg"
                  onClick={() => navigate('/companies')}
                >
                  Browse Companies
                </Button>
              </Card.Body>
            </FeatureCard>
          </Col>

          <Col md={4}>
            <FeatureCard>
              <Card.Body className="text-center p-5">
                <FeatureIcon>
                  <i className="fas fa-toolbox"></i>
                </FeatureIcon>
                <Card.Title className="h3 mb-4">Career Tools</Card.Title>
                <Card.Text className="mb-4">
                  Access our comprehensive suite of career preparation tools including interview prep,
                  networking guides, industry research, and financial planning resources.
                </Card.Text>
                <Button 
                  variant="primary" 
                  size="lg"
                  onClick={() => navigate('/career-tools')}
                >
                  Access Tools
                </Button>
              </Card.Body>
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;

import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

const PageTitle = styled.h1`
  color: #0052a3;
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 1rem 0;
  font-size: 2rem;
`;

const StyledCard = styled(Card)`
  margin-bottom: 2rem;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ResourceCard = styled(Card)`
  height: 100%;
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const NetworkingGuide = () => {
  const networkingResources = [
    {
      title: "LinkedIn Profile Optimizer",
      icon: "fab fa-linkedin",
      description: "Learn how to optimize your LinkedIn profile for maximum visibility and professional impact.",
      tips: [
        "Use a professional photo",
        "Write a compelling headline",
        "Craft an engaging summary",
        "Highlight key achievements"
      ]
    },
    {
      title: "Email Templates",
      icon: "fas fa-envelope",
      description: "Professional email templates for various networking scenarios.",
      tips: [
        "Cold outreach emails",
        "Follow-up messages",
        "Thank you notes",
        "Meeting requests"
      ]
    },
    {
      title: "Elevator Pitch Builder",
      icon: "fas fa-bullhorn",
      description: "Create and refine your professional elevator pitch.",
      tips: [
        "Keep it under 30 seconds",
        "Highlight unique value",
        "Practice delivery",
        "Adapt for context"
      ]
    },
    {
      title: "Network Tracker",
      icon: "fas fa-users",
      description: "Tools and templates to maintain and grow your professional network.",
      tips: [
        "Regular follow-ups",
        "Track interactions",
        "Set networking goals",
        "Monitor relationships"
      ]
    }
  ];

  return (
    <Container className="py-3">
      <PageTitle>Networking Guide</PageTitle>

      <Row className="g-4">
        {networkingResources.map((resource, index) => (
          <Col md={6} key={index}>
            <ResourceCard>
              <Card.Body className="p-4">
                <div className="d-flex align-items-center mb-3">
                  <i className={`${resource.icon} fa-2x text-primary me-3`}></i>
                  <h3 className="mb-0">{resource.title}</h3>
                </div>
                <Card.Text>{resource.description}</Card.Text>
                <h6 className="text-primary mt-3">Key Tips:</h6>
                <ul className="list-unstyled">
                  {resource.tips.map((tip, idx) => (
                    <li key={idx} className="mb-2">
                      <i className="fas fa-check-circle text-success me-2"></i>
                      {tip}
                    </li>
                  ))}
                </ul>
                <Button variant="outline-primary" className="w-100 mt-3">
                  Learn More
                </Button>
              </Card.Body>
            </ResourceCard>
          </Col>
        ))}
      </Row>

      <StyledCard className="mt-4">
        <Card.Body>
          <h4 className="text-primary mb-3">Coming Soon:</h4>
          <ul className="list-unstyled">
            <li className="mb-2">
              <i className="fas fa-clock text-warning me-2"></i>
              Virtual Networking Event Platform
            </li>
            <li className="mb-2">
              <i className="fas fa-clock text-warning me-2"></i>
              Professional Social Media Guide
            </li>
            <li className="mb-2">
              <i className="fas fa-clock text-warning me-2"></i>
              Networking Analytics Dashboard
            </li>
          </ul>
        </Card.Body>
      </StyledCard>
    </Container>
  );
};

export default NetworkingGuide; 
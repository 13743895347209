import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const CareerList = () => {
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [careerJobs, setCareerJobs] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'occupation', direction: 'ascending' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchCareers();
  }, []);

  const fetchCareers = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin');
        return;
      }

      console.log('Fetching careers data...');
      const response = await axios.get('/api/admin/careers', {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('Careers data received:', response.data);
      if (response.data.length > 0) {
        console.log('First career structure:', response.data[0]);
      }

      setCareers(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching careers:', err);
      console.error('Error response:', err.response?.data);
      setError(err.response?.data?.message || 'Error loading careers');
      setLoading(false);

      if (err.response?.status === 401) {
        navigate('/admin');
      }
    }
  };

  const handleShowJobs = async (career) => {
    try {
      console.log('Fetching jobs for career:', career);
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`/api/admin/careers/${career.id}/jobs`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      console.log('Jobs received:', response.data);
      setCareerJobs(response.data);
      setSelectedCareer(career);
      setShowModal(true);
    } catch (err) {
      console.error('Error fetching career jobs:', err);
      console.error('Error response:', err.response?.data);
    }
  };

  const sortedCareers = React.useMemo(() => {
    let sortableCareers = [...careers];
    if (sortConfig !== null) {
      sortableCareers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCareers;
  }, [careers, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredCareers = sortedCareers.filter(career => 
    career.occupation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    career.onet_soc_code.includes(searchTerm)
  );

  if (loading) return <div className="container mt-4">Loading...</div>;
  if (error) return <div className="container mt-4 alert alert-danger">{error}</div>;

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Careers Management</h2>
        <div className="w-25">
          <input
            type="text"
            className="form-control"
            placeholder="Search careers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th onClick={() => requestSort('onet_soc_code')}>ONET SOC Code</th>
            <th onClick={() => requestSort('occupation')}>Occupation</th>
            <th onClick={() => requestSort('jobCount')}>Number of Jobs</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCareers.map(career => (
            <tr key={career.id}>
              <td>{career.onet_soc_code}</td>
              <td>{career.occupation}</td>
              <td>{career.jobCount || career.jobs?.length || 0}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleShowJobs(career)}
                >
                  View Jobs
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Jobs for {selectedCareer?.occupation}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {careerJobs.length === 0 ? (
            <p>No jobs found for this career.</p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Job Title</th>
                  <th>Company</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {careerJobs.map(job => (
                  <tr key={job.id}>
                    <td>{job.job_title}</td>
                    <td>{job.company_name}</td>
                    <td>
                      {job.city ? `${job.city.city}, ${job.city.state_name}` : 'N/A'}
                    </td>
                    <td>
                      <Link 
                        to={`/admin/jobs/edit/${job.id}`}
                        className="btn btn-secondary btn-sm"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CareerList; 
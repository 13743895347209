import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    jobs: 0,
    careers: 0,
    cities: 0
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        
        if (!token) {
          console.log('No token found, redirecting to login');
          navigate('/admin');
          return;
        }

        console.log('Fetching stats with token:', token.substring(0, 20) + '...');
        
        const response = await axios.get('/api/admin/stats', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        console.log('Stats response:', response.data);
        setStats(response.data);
      } catch (err) {
        console.error('Error fetching stats:', err);
        console.error('Error response:', err.response?.data);
        setError(err.response?.data?.details || err.message);
        
        if (err.response?.status === 401) {
          console.log('Unauthorized, redirecting to login');
          navigate('/admin');
        }
      }
    };
    fetchStats();
  }, [navigate]);

  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">
          Error loading dashboard: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Admin Dashboard</h2>
        <button 
          onClick={() => {
            localStorage.removeItem('adminToken');
            navigate('/admin');
          }}
          className="btn btn-outline-danger"
        >
          Logout
        </button>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Jobs</h5>
              <p className="card-text">{stats.jobs} total jobs</p>
              <div className="d-flex gap-2">
                <Link to="/admin/jobs" className="btn btn-primary">Manage Jobs</Link>
                <Link to="/admin/jobs/upload" className="btn btn-secondary">Upload Jobs</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Careers</h5>
              <p className="card-text">{stats.careers} total careers</p>
              <Link to="/admin/careers" className="btn btn-primary">Manage Careers</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Cities</h5>
              <p className="card-text">{stats.cities} total cities</p>
              <Link to="/admin/cities" className="btn btn-primary">Manage Cities</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Users</h5>
              <Link to="/admin/users" className="btn btn-primary">Manage Users</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Companies</h5>
              <Link to="/admin/companies" className="btn btn-primary">Manage Companies</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Career Collections</h5>
                <p className="card-text">Manage career collections</p>
                <Link to="/admin/collections" className="btn btn-primary">Manage Collections</Link>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const JobDataUpload = () => {
  const [jsonData, setJsonData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const navigate = useNavigate();

  const claudePrompt = `Please create job listings for the following parameters:

Region: [X] (e.g., "Dallas-Fort Worth Metroplex")
Industry: [Y] (e.g., "Technology")
Position Types: [Z] (e.g., "Software Engineering, DevOps, Cloud Architecture")

For each job listing, please provide:
{
  "ONET_SOC_CODE": "Use appropriate O*NET SOC code for the position",
  "Occupation": "Standard occupation title",
  "Company_Name": "Real company in the region",
  "Job_Title": "Specific job title",
  "Job_Description": "Detailed job description in HTML format including:
    - Brief company intro
    - Key responsibilities
    - Required qualifications
    - Location (City, State format)
    - Compensation range",
  "Job_City": "City name",
  "Job_State": "Two-letter state code"
}

Please ensure:
1. ONET_SOC_CODE matches the occupation from the O*NET database
2. Company_Name uses real companies with offices in the specified region
3. Job_Description uses HTML formatting (<p>, <ul>, <li>, etc.)
4. Location information is consistent between Job_City/Job_State and the description
5. Each listing includes realistic compensation ranges for the region`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResults(null);

    try {
      const jobsData = JSON.parse(jsonData);
      const dataToSend = Array.isArray(jobsData) ? jobsData : [jobsData];

      const token = localStorage.getItem('adminToken');
      const response = await axios.post('/api/admin/jobs/upload', 
        { jobs: dataToSend },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      setResults(response.data);
    } catch (err) {
      if (err.name === 'SyntaxError') {
        setError('Invalid JSON format. Please check your input.');
      } else {
        setError(err.response?.data?.error || 'Error uploading jobs data');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Upload Jobs Data</h2>
        <button 
          onClick={() => navigate('/admin/dashboard')}
          className="btn btn-outline-secondary"
        >
          Back to Dashboard
        </button>
      </div>

      {error && (
        <div className="alert alert-danger">{error}</div>
      )}

      {results && (
        <div className="alert alert-success">
          <h5>Upload Results:</h5>
          <p>Successfully added: {results.success} jobs</p>
          {results.failures.length > 0 && (
            <>
              <p>Failed to add: {results.failures.length} jobs</p>
              <ul>
                {results.failures.map((failure, index) => (
                  <li key={index}>
                    {failure.job.Job_Title}: {failure.reason}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}

      <div className="row">
        {/* Left side: JSON Upload */}
        <div className="col-md-6">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Upload JSON Data</h5>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="jsonData" className="form-label">
                    Paste JSON Jobs Data:
                  </label>
                  <textarea
                    id="jsonData"
                    className="form-control font-monospace"
                    rows="20"
                    value={jsonData}
                    onChange={(e) => setJsonData(e.target.value)}
                    placeholder='Paste JSON data here...'
                  />
                </div>
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Uploading...' : 'Upload Jobs'}
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Right side: Claude Prompt Template */}
        <div className="col-md-6">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Claude Prompt Template</h5>
              <div className="mb-3">
                <textarea
                  className="form-control font-monospace"
                  rows="20"
                  value={claudePrompt}
                  readOnly
                  onClick={(e) => {
                    e.target.select();
                    navigator.clipboard.writeText(claudePrompt);
                  }}
                />
                <small className="text-muted">
                  Click the template to copy it to your clipboard
                </small>
              </div>
              <div className="alert alert-info">
                <h6>Example Usage:</h6>
                <p>Please create job listings for the following parameters:</p>
                <p>
                  Region: Texas Triangle (Dallas-Austin-Houston)<br />
                  Industry: Technology<br />
                  Position Types: Software Engineering, Cloud Architecture, DevOps Engineering
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDataUpload;
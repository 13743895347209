import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 1000;

`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <Navbar expand="lg" className="navbar-light">
          <Link to="/" className="navbar-brand">
            <img src="/images/wcplogo.png" alt="Defined Workforce Logo" height="60" />
          </Link>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/job-board">
                <i className="fas fa-briefcase me-1"></i>
                Simulated Job Board
              </Nav.Link>
              <Nav.Link as={Link} to="/companies">
                <i className="fas fa-building me-1"></i>
                Company Directory
              </Nav.Link>
              <Nav.Link as={Link} to="/career-tools">
                <i className="fas fa-toolbox me-1"></i>
                Career Tools
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingOverlay = () => {
  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        flexDirection: 'column'
      }}
    >
      <Spinner 
        animation="border" 
        variant="primary" 
        style={{ width: '3rem', height: '3rem' }}
      />
      <div className="mt-3 text-primary">
        <h4>Analyzing Your Application</h4>
        <p className="text-muted">This may take a few moments...</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import JobBoard from './components/JobBoard';
import JobListing from './components/JobListing';
import Interview from './components/Interview';
import InterviewFeedback from './components/InterviewFeedback';
import InterviewReport from './components/InterviewReport';
import CoursePlayer from './components/CoursePlayer';
import JobApplication from './components/JobApplication';
import ErrorBoundary from './components/ErrorBoundary';
import CareerCoach from './components/CareerCoach';
import CareerCourses from './components/CareerCourses';
import CareerTools from './components/CareerTools';
import InterviewPrep from './components/InterviewPrep';
import NetworkingGuide from './components/NetworkingGuide';
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import JobEditor from './components/admin/JobEditor';
import JobList from './components/admin/JobList';
import JobDataUpload from './components/admin/JobDataUpload';
import CompanyDetail from './components/CompanyDetail';
import CompanyEditor from './components/admin/CompanyEditor';
import CompanyList from './components/admin/CompanyList';
import CareerList from './components/admin/CareerList';
import AdminBreadcrumb from './components/admin/AdminBreadcrumb';
import CollectionList from './components/admin/CollectionList';
import CollectionEdit from './components/admin/CollectionEdit';
import CollectionManage from './components/admin/CollectionManage';
import CompanyHome from './components/CompanyHome';
import CompanyBoard from './components/CompanyBoard';

// Add at the top of your App component
console.log('React App Loaded!');

function App() {
  useEffect(() => {
    // Initialize Matomo Tag Manager
    window._mtm = window._mtm || [];
    window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://log.definedlearning.com/js/container_TPxL94eD.js';
    
    document.head.appendChild(script);
    
    // Cleanup function to remove the script if component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once

  return (
    <ErrorBoundary>
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Header />
          <AdminBreadcrumb />
          <main className="flex-grow-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/job-board" element={<JobBoard />} />
              <Route path="/companies" element={<CompanyBoard />} />
              <Route path="/companies/:id" element={<CompanyDetail />} />
              <Route path="/companyhome/:id" element={<CompanyHome />} />
              <Route path="/job-listing/:id" element={<JobListing />} />
              <Route path="/interview/:id" element={<Interview />} />
              <Route path="/feedback/:referenceId" element={<InterviewFeedback />} />
              <Route path="/interview-report/:referenceId" element={<InterviewReport />} />
              <Route path="/career-courses" element={<CareerCourses />} />
              <Route path="/courses" element={<CoursePlayer />} />
              <Route path="/apply/:id" element={<JobApplication />} />
              <Route path="/career-coach" element={<CareerCoach />} />
              <Route path="/career-tools" element={<CareerTools />} />
              <Route path="/tools/interview-prep" element={<InterviewPrep />} />
              <Route path="/tools/networking" element={<NetworkingGuide />} />
              <Route path="/admin" element={<AdminLogin />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/jobs" element={<JobList />} />
              <Route path="/admin/jobs/new" element={<JobEditor />} />
              <Route path="/admin/jobs/edit/:id" element={<JobEditor />} />
              <Route path="/admin/jobs/upload" element={<JobDataUpload />} />
              <Route path="/admin/companies" element={<CompanyList />} />
              <Route path="/admin/companies/new" element={<CompanyEditor />} />
              <Route path="/admin/companies/edit/:id" element={<CompanyEditor />} />
              <Route path="/admin/companies/:id" element={<CompanyEditor />} />
              <Route path="/admin/careers" element={<CareerList />} />
              <Route path="/admin/collections" element={<CollectionList />} />
              <Route path="/admin/collections/new" element={<CollectionEdit />} />
              <Route path="/admin/collections/:id/edit" element={<CollectionEdit />} />
              <Route path="/admin/collections/:id/manage" element={<CollectionManage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;

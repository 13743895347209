import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        console.log('Fetching jobs with token:', token ? 'Token exists' : 'No token');
        
        const response = await axios.get('/api/admin/jobs', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        console.log('Jobs response:', response.data);
        setJobs(response.data);
        setFilteredJobs(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching jobs:', err);
        console.error('Error response:', err.response?.data);
        setError(err.response?.data?.details || err.message);
        setLoading(false);
        
        if (err.response?.status === 401) {
          navigate('/admin');
        }
      }
    };

    fetchJobs();
  }, [navigate]);

  // Handle search across all fields
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (!searchTerm.trim()) {
      setFilteredJobs(jobs);
      return;
    }

    const filtered = jobs.filter(job => {
      return (
        job.job_title?.toLowerCase().includes(searchTerm) ||
        job.company_name?.toLowerCase().includes(searchTerm) ||
        job.city?.city?.toLowerCase().includes(searchTerm) ||
        job.city?.state_name?.toLowerCase().includes(searchTerm) ||
        job.career?.occupation?.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredJobs(filtered);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        const token = localStorage.getItem('adminToken');
        await axios.delete(`/api/admin/jobs/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setJobs(jobs.filter(job => job.id !== id));
        setFilteredJobs(filteredJobs.filter(job => job.id !== id));
      } catch (err) {
        setError('Error deleting job');
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Jobs</h2>
        <Link to="/admin/jobs/new" className="btn btn-primary">
          Add New Job
        </Link>
      </div>

      {/* Add search input */}
      <div className="mb-4">
        <div className="input-group">
          <span className="input-group-text">
            <i className="fas fa-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={handleSearch}
          />
          {searchTerm && (
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                setSearchTerm('');
                setFilteredJobs(jobs);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          )}
        </div>
        {searchTerm && (
          <small className="text-muted">
            Found {filteredJobs.length} of {jobs.length} jobs
          </small>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Company</th>
              <th>Location</th>
              <th>Career</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map(job => (
              <tr key={job.id}>
                <td>{job.job_title}</td>
                <td>{job.company_name}</td>
                <td>{job.city?.city}, {job.city?.state_name}</td>
                <td>{job.career?.occupation}</td>
                <td>
                  <span className={`badge ${job.is_active ? 'bg-success' : 'bg-danger'}`}>
                    {job.is_active ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td>
                  <Link to={`/admin/jobs/edit/${job.id}`} className="btn btn-sm btn-primary me-2">
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(job.id)}
                    className="btn btn-sm btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobList; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'video'],
      ['clean']
    ]
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link', 'video'
];

const JobEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({
    job_title: '',
    company_id: null,
    company_name: '',
    job_description: '',
    career_id: '',
    city_id: '',
    is_active: true
  });
  const [careers, setCareers] = useState([]);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const headers = { Authorization: `Bearer ${token}` };

        // Fetch careers and initial companies
        const [careersResponse, companiesResponse] = await Promise.all([
          axios.get('/api/admin/careers', { headers }),
          axios.get('/api/admin/companies', { headers })
        ]);

        // Transform career data for react-select
        const careerOptions = careersResponse.data.map(career => ({
          value: career.id,
          label: career.occupation
        }));

        setCareers(careerOptions);

        // If editing existing job, fetch job data
        if (id) {
          const jobResponse = await axios.get(`/api/admin/jobs/${id}`, { headers });
          const jobData = jobResponse.data;
          console.log('Fetched job data:', jobData);
          
          setJob(jobData);
          
          // Set selected options for react-select
          if (jobData.career) {
            setSelectedCareer({
              value: jobData.career.id,
              label: jobData.career.occupation
            });
          }
          if (jobData.city) {
            setSelectedCity({
              value: jobData.city.id,
              label: `${jobData.city.city}, ${jobData.city.state_name}`
            });
          }
          if (jobData.company) {
            const companyOption = {
              value: jobData.company.id,
              label: jobData.company.name
            };
            console.log('Setting selected company:', companyOption);
            setSelectedCompany(companyOption);
          }
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
        
        if (err.response?.status === 401) {
          navigate('/admin');
        }
      }
    };

    fetchData();
  }, [id, navigate]);

  // New function to load city options
  const loadCityOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) return [];
    
    try {
      const token = localStorage.getItem('adminToken');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`/api/admin/cities/search?query=${inputValue}`, { headers });
      
      return response.data.map(city => ({
        value: city.id,
        label: `${city.city}, ${city.state_name}`
      }));
    } catch (error) {
      console.error('Error loading cities:', error);
      return [];
    }
  };

  // Load companies for dropdown
  const loadCompanyOptions = async (inputValue) => {
    try {
      console.log('Loading company options for:', inputValue);
      const token = localStorage.getItem('adminToken');
      const headers = { Authorization: `Bearer ${token}` };
      
      if (!inputValue) {
        // If no input, fetch all companies (limited)
        const response = await axios.get('/api/admin/companies', { headers });
        console.log('Loaded initial companies:', response.data);
        return response.data.map(company => ({
          value: company.id,
          label: company.name
        }));
      }

      // Otherwise, search companies
      const response = await axios.get(`/api/admin/companies/search?query=${inputValue}`, { headers });
      console.log('Search results:', response.data);
      return response.data.map(company => ({
        value: company.id,
        label: company.name
      }));
    } catch (error) {
      console.error('Error loading companies:', error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('adminToken');
      const headers = { Authorization: `Bearer ${token}` };

      const jobData = {
        ...job,
        career_id: selectedCareer?.value,
        city_id: selectedCity?.value,
        company_id: selectedCompany?.value
      };

      if (id) {
        await axios.put(`/api/admin/jobs/${id}`, jobData, { headers });
      } else {
        await axios.post('/api/admin/jobs', jobData, { headers });
      }

      navigate('/admin/jobs');
    } catch (err) {
      console.error('Error saving job:', err);
      setError(err.message);
    }
  };

  // When a company is selected, update both company_id and company_name
  const handleCompanyChange = (selected) => {
    console.log('Company selected:', selected);
    setSelectedCompany(selected);
    setJob({
      ...job,
      company_id: selected?.value || null,
      company_name: selected?.label || ''
    });
  };

  if (loading) return <div className="admin-editor">Loading...</div>;
  if (error) return <div className="admin-editor alert alert-danger">Error: {error}</div>;

  return (
    <div className="admin-editor">
      <h2>{id ? 'Edit Job' : 'Create Job'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Job Title</label>
          <input
            type="text"
            className="form-control"
            value={job.job_title}
            onChange={(e) => setJob({ ...job, job_title: e.target.value })}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Company</label>
          <div className="d-flex align-items-center gap-2">
            <div className="flex-grow-1">
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={selectedCompany}
                onChange={handleCompanyChange}
                loadOptions={loadCompanyOptions}
                isClearable
                placeholder="Search for a company..."
                noOptionsMessage={() => "Type to search companies"}
              />
            </div>
            {selectedCompany && (
              <Link 
                to={`/admin/companies/${selectedCompany.value}`}
                className="btn btn-outline-primary"
                title="Edit Company"
              >
                Edit
              </Link>
            )}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Description</label>
          <ReactQuill
            modules={modules}
            formats={formats}
            value={job.job_description}
            onChange={(value) => setJob({...job, job_description: value})}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Career</label>
          <Select
            value={selectedCareer}
            onChange={setSelectedCareer}
            options={careers}
            isSearchable={true}
            placeholder="Search and select career..."
          />
        </div>
        <div className="mb-3">
          <label className="form-label">City</label>
          <AsyncSelect
            value={selectedCity}
            onChange={setSelectedCity}
            loadOptions={loadCityOptions}
            isSearchable={true}
            placeholder="Type to search cities..."
            noOptionsMessage={({ inputValue }) => 
              inputValue.length < 2 
                ? "Type at least 2 characters to search" 
                : "No cities found"
            }
          />
        </div>
        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={job.is_active}
              onChange={(e) => setJob({...job, is_active: e.target.checked})}
            />
            Active
          </label>
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    </div>
  );
};

export default JobEditor;
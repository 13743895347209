import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Button, Row, Col, Container, Badge } from 'react-bootstrap';
import { fetchCompany } from '../services/api';

const CompanyDetail = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Default office building image from Unsplash
  const defaultCompanyImage = "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80&w=1200";
  
  // Placeholder course images from Unsplash
  const courseImages = [
    "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?auto=format&fit=crop&q=80&w=600", // Team meeting
    "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=600",    // Presentation
    "https://images.unsplash.com/photo-1507537297725-24a1c029d3ca?auto=format&fit=crop&q=80&w=600"  // Learning
  ];

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (!id) {
        setError('No company ID provided');
        setLoading(false);
        return;
      }

      try {
        const data = await fetchCompany(id);
        setCompany(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching company:', err);
        setError(err.response?.data?.error || 'Failed to fetch company details');
        setCompany(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [id]);

  if (loading) return <div className="admin-editor">Loading...</div>;
  if (error) return <div className="admin-editor alert alert-danger">Error: {error}</div>;
  if (!company) return <div className="alert alert-warning">Company not found.</div>;

  return (
    <div className="company-detail">
      {/* Hero Section */}
      <div className="company-hero position-relative mb-5">
        <div className="company-hero-image" 
             style={{
               backgroundImage: `url(${company.logo_url || defaultCompanyImage})`,
               height: '300px',
               backgroundSize: 'cover',
               backgroundPosition: 'center',
               position: 'relative'
             }}>
          <div className="overlay" style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6))'
          }}></div>
          <Container className="h-100">
            <div className="d-flex align-items-end h-100 position-relative pb-4 text-white">
              <div>
                <h1 className="display-4 fw-bold mb-2">{company.name}</h1>
                {company.website && (
                  <a href={company.website} target="_blank" rel="noopener noreferrer" 
                     className="text-white text-decoration-none">
                    <i className="fas fa-globe me-2"></i>
                    Visit Website
                  </a>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Container>
        {/* Open Positions Section */}
        <section className="mb-5">
          <h2 className="h3 mb-4">Simulated Open Positions</h2>
          <Row className="g-4">
            {company.jobs && company.jobs.length > 0 ? (
              company.jobs.map((job) => (
                <Col key={job.id} lg={3} md={4} sm={6}>
                  <Link to={`/job-listing/${job.id}`} className="text-decoration-none">
                    <Card className="h-100 border-0 shadow-sm hover-card">
                      <Card.Body>
                        <h3 className="h5 text-primary">{job.job_title}</h3>
                        <div className="d-flex flex-wrap gap-2 my-2">
                          {job.city && (
                            <Badge bg="light" text="dark">
                              <i className="fas fa-map-marker-alt me-1"></i>
                              {job.city.city}
                            </Badge>
                          )}
                        </div>
                        <Button variant="outline-primary" size="sm" className="w-100">
                          View Position
                        </Button>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))
            ) : (
              <Col>
                <Card className="border-0 shadow-sm text-center p-4">
                  <Card.Body>
                    <i className="fas fa-briefcase text-muted mb-3" style={{ fontSize: '2rem' }}></i>
                    <p className="mb-0">No open positions at this time</p>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </section>

        {/* Training Opportunities Section */}
        <section className="mb-5">
          <h2 className="h3 mb-4">Training Opportunities</h2>
          <Row className="g-4">
            {courseImages.map((image, index) => (
              <Col key={index} md={4}>
                <Card className="border-0 shadow-sm hover-card h-100">
                  <div className="course-image" style={{
                    height: '160px',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}></div>
                  <Card.Body>
                    <h3 className="h5">Coming Soon</h3>
                    <p className="text-muted small mb-0">
                      New training opportunities will be available soon.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </section>

        {/* Company Description Section */}
        <section className="mb-5">
          <Card className="border-0 shadow-sm">
            <Card.Body className="p-4">
              <h2 className="h4 mb-3">About {company.name}</h2>
              <div className="company-description">
                {company.description ? (
                  <div 
                    className="rich-text-content"
                    dangerouslySetInnerHTML={{ __html: company.description }}
                  />
                ) : (
                  <p className="text-muted mb-0">No company description available.</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </section>
      </Container>
    </div>
  );
};

export default CompanyDetail;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { fetchJob } from '../services/api';


const JobListing = () => {
  const [jobData, setJobData] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        if (!id) {
          throw new Error('No job ID provided in the URL');
        }

        const data = await fetchJob(id);
        console.log('Job data from API:', data);
        setJobData(data);
      } catch (error) {
        console.error('Error in JobListing:', error);
        setError(error.message);
      }
    };

    fetchJobDetails();
  }, [id]);

  const handleStartInterview = () => {
    navigate(`/interview/${id}`);
  };

  const handleApplyForPosition = () => {
    navigate(`/apply/${id}`);
  };

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error: {error}. Please try selecting a job again.
      </div>
    );
  }

  if (!jobData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container py-4">
      <Card className="shadow-sm">
        <Card.Body>
          <h1 className="card-title mb-2">{jobData.job_title}</h1>
          <h2 className="text-muted mb-4">
            <i className="fas fa-building me-2"></i>
            <Link to={`/companies/${jobData.company_id}`} className="text-decoration-none">
              {jobData.company ? jobData.company.name : 'Loading company...'}
            </Link>
          </h2>
          <div className="mb-4">
            <h3 className="h5 mb-3">Job Description</h3>
            <div 
              className="card-text rich-text-content"
              dangerouslySetInnerHTML={{ __html: jobData.job_description }}
            />
          </div>
          <Row className="mt-4">
            <Col md={6} className="mb-3 mb-md-0">
              <Button 
                variant="success" 
                size="lg" 
                className="w-100"
                onClick={handleApplyForPosition}
              >
                <i className="fas fa-file-upload me-2"></i>
                Apply for Position
              </Button>
            </Col>
            <Col md={6}>
              <Button 
                variant="primary" 
                size="lg" 
                className="w-100"
                onClick={handleStartInterview}
              >
                <i className="fas fa-microphone-alt me-2"></i>
                Start Simulated Interview
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default JobListing;

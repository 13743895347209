import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

const QuestionDisplay = ({ question, questionNumber, totalQuestions }) => {
  useEffect(() => {
    // Play question audio if available
    const playQuestionAudio = async () => {
      try {
        if (!question) return;
        
        const audioFileName = `question_${question.job_id}_${question.level}_${question.id}.mp3`;
        const audioUrl = process.env.NODE_ENV === 'production'
          ? `https://dcworkforce.s3.us-east-1.amazonaws.com/audio/${audioFileName}`
          : `/audio/${audioFileName}`;

        const audio = new Audio(audioUrl);
        await audio.play();
      } catch (error) {
        console.error('Error playing question audio:', error);
      }
    };

    playQuestionAudio();
  }, [question]);

  if (!question) return null;

  return (
    <Card className="mb-4">
      <Card.Body>
        <h3 className="h5 mb-2">
          <i className="fas fa-question-circle me-2"></i>
          Question {questionNumber} of {totalQuestions}:
        </h3>
        <p className="lead mb-0">{question.text}</p>
      </Card.Body>
    </Card>
  );
};

export default QuestionDisplay;

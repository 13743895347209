import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

const ResponseRecorder = ({
  isRecording,
  onStartRecording,
  onStopRecording,
  onSubmitResponse
}) => {
  const [response, setResponse] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);

  console.log('ResponseRecorder render - current response:', response);
  console.log('ResponseRecorder render - isRecording:', isRecording);
  console.log('ResponseRecorder render - isTranscribing:', isTranscribing);

  const handleToggleRecording = async () => {
    console.log('handleToggleRecording called - isRecording:', isRecording);
    if (isRecording) {
      console.log('Stopping recording...');
      const audioBlob = await onStopRecording();
      console.log('Received audioBlob:', audioBlob);
      if (audioBlob) {
        await handleTranscription(audioBlob);
      }
    } else {
      console.log('Starting recording...');
      try {
        await onStartRecording();
      } catch (error) {
        console.error('Error starting recording:', error);
        const errorMessage = error.message || 'Error accessing microphone. Please check your permissions and try again.';
        alert(errorMessage);
      }
    }
  };

  const handleTranscription = async (audioBlob) => {
    try {
      console.log('handleTranscription started');
      setIsTranscribing(true);
      const formData = new FormData();
      
      // Convert webm to mp3 if needed for better compatibility
      const finalBlob = audioBlob;
      formData.append('audio', finalBlob, 'recording.webm');

      // Add content type header for server identification
      const headers = new Headers();
      headers.append('Accept', 'application/json');

      console.log('Sending audio for transcription...');
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        headers,
        body: formData
      });

      console.log('Raw response:', response);
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Transcription response data:', data);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (data && data.transcription) {
        console.log('Setting transcription text:', data.transcription);
        const newResponse = data.transcription.trim();
        console.log('New trimmed response:', newResponse);
        
        setResponse(newResponse);
        console.log('Response state updated to:', newResponse);
      } else {
        console.error('No transcription in response:', data);
        throw new Error('No transcription received');
      }
    } catch (error) {
      console.error('Transcription error:', error);
      alert('Error transcribing audio. Please try again.');
    } finally {
      console.log('Transcription process completed');
      setIsTranscribing(false);
    }
  };

  const handleSubmit = async () => {
    console.log('handleSubmit called - current response:', response);
    if (response.trim()) {
      await onSubmitResponse(response);
      setResponse('');
      console.log('Response cleared after submission');
    }
  };

  useEffect(() => {
    console.log('Response state changed:', response);
  }, [response]);

  return (
    <div className="response-section">
      <Form.Group className="mb-3">
        <Form.Label><h3 className="h5">Your Response</h3></Form.Label>
        <Form.Control
          as="textarea"
          rows={6}
          value={response}
          onChange={(e) => {
            console.log('Textarea onChange - new value:', e.target.value);
            setResponse(e.target.value);
          }}
          placeholder={isTranscribing ? 'Transcribing...' : 'Your response will appear here...'}
          readOnly={isRecording || isTranscribing}
          className={`form-control ${isTranscribing ? 'loading' : ''}`}
        />
      </Form.Group>

      <div className="d-flex gap-2">
        <Button
          variant={isRecording ? 'danger' : 'success'}
          onClick={handleToggleRecording}
          className="flex-grow-1"
        >
          <i className={`fas fa-${isRecording ? 'stop' : 'microphone'} me-2`}></i>
          {isRecording ? 'Stop Recording' : 'Record Response'}
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!response.trim() || isRecording}
          className={`flex-grow-1 ${response.trim() && !isRecording ? 'btn-glow' : ''}`}
        >
          <i className="fas fa-paper-plane me-2"></i>
          Submit Response
        </Button>
      </div>

      {isRecording && (
        <div className="text-center mt-3">
          <span className="badge bg-danger me-2 pulse"></span>
          <span className="text-danger fw-bold">Recording in progress</span>
        </div>
      )}
    </div>
  );
};

export default ResponseRecorder;
